<template>
  <div class="container">
    <top-nav :imgList="banner" isParent>
      <div class="banner-item-title">
        {{ globalData.smartPort.txt
        }}<span class="banner-item-sp">{{ globalData.smartPort.sp }}</span>
      </div>
      <div class="banner-item-brief" v-html="globalData.smartPort.brief"></div>
    </top-nav>
    <div class="structure">
      <div class="card-box flex space-between content-limit">
        <pin-card
          v-for="t in block1"
          :key="t.title"
          :title="t.title"
          :icon="t.icon"
          :sub="t.sub"
        ></pin-card>
      </div>
      <block-title
        :title="globalData.smartPort.structure.top.title"
        :en="globalData.smartPort.structure.top.en"
        :sub="globalData.smartPort.structure.top.sub"
      ></block-title>
      <img
        class="content-limit img"
        src="@/asset/smart-port/ic_port_08.png"
        alt=""
      />
    </div>
    <div class="advantage">
      <div class="en">{{ globalData.smartPort.advantage.en }}</div>
      <div class="title">{{ globalData.smartPort.advantage.title }}</div>
      <div class="brief">{{ globalData.smartPort.advantage.brief }}</div>
      <div class="tabs">
        <div
          class="item"
          v-for="(item, index) of advantage"
          :key="index"
          @click="advCurrentIndex = index"
        >
          <img
            class="icon"
            v-if="advCurrentIndex === index"
            :src="item.left.activeIcon"
            alt=""
          />
          <img class="icon" v-else :src="item.left.icon" alt="" />
          <div class="txt">{{ item.left.txt }}</div>
        </div>

        <div
          class="item-strip"
          :style="{ left:100/advantage.length * advCurrentIndex + '%' }"
        ></div>
      </div>
      <template v-for="(item, index) of advantage">
        <div
          class="content content-limit"
          v-if="advCurrentIndex === index"
          :key="index"
        >
          <img class="img" :src="item.right.img" alt="" />
          <div class="right">
            <div class="right-title">{{ item.right.title }}</div>
            <div class="right-brief">
              {{ item.right.brief }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="application">
      <block-title
        :title="globalData.smartPort.application.top.title"
        :en="globalData.smartPort.application.top.en"
        :sub="globalData.smartPort.application.top.sub"
      ></block-title>
      <display-list style="margin-top: 70px" :list="block2"></display-list>
    </div>
    <my-footer></my-footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PinCard from "../../components/card-list/pin-card.vue";
import DisplayList from "../../components/display-list/index.vue";

export default {
  data() {
    return {
      banner: require("@/asset/smart-port/ic_port_11.png"),
      advCurrentIndex: 0,
    };
  },
  components: {
    PinCard,
    DisplayList,
  },
  computed: {
    ...mapGetters(["globalData"]),
    block1() {
      return window.deepMerge(
        [
          {
            title: "降本增效",
            sub: "灵活部署，快速实现智能化全流程",
            icon: require("@/asset/smart-port/ic_port_05.png"),
          },
          {
            title: "精准识别",
            sub: "高性能人工智能化平台",
            icon: require("@/asset/smart-port/ic_port_06.png"),
          },
          {
            title: "数据利用",
            sub: "通过可视化呈现，增强数据共享与决策",
            icon: require("@/asset/smart-port/ic_port_07.png"),
          },
        ],
        this.globalData.smartPort.structure.block1
      );
    },
    advantage() {
      return window.deepMerge(
        [
          {
            left: {
              icon: require("@/asset/smart-port/ic_port_12.png"),
              activeIcon: require("@/asset/smart-port/ic_port_01.png"),
            },
            right: {
              img: require("@/asset/smart-port/ic_port_09.png"),
            },
          },
          {
            left: {
              icon: require("@/asset/smart-port/ic_port_02.png"),
              activeIcon: require("@/asset/smart-port/ic_port_14.png"),
            },
            right: {
              img: require("@/asset/smart-port/ic_port_19.png"),
            },
          },
          {
            left: {
              icon: require("@/asset/smart-port/ic_port_03.png"),
              activeIcon: require("@/asset/smart-port/ic_port_13.png"),
            },
            right: {
              img: require("@/asset/smart-port/ic_port_20.png"),
            },
          },
        ],
        this.globalData.smartPort.advantage.list
      );
    },
    block2() {
      return window.deepMerge(
        [
          {
            title: "基础设施智慧化",
            description:
              "加快推进新型基础设施建设，开展港区5G基站与北斗地面增强组网建设，推进索5G与北斗技术在“智慧港口”建设中的成熟应用，提升5G、北斗技术对港口行业工业互联网的支撑能力。搭建港口云数据中心，拓展港航大数据集成共享新空间，全面支撑企业核心应用系统的稳定运行与港航大数据的开发运营。",
            icon: require("@/asset/smart-port/ic_port_15.png"),
            btn: {
              label: "在线咨询",
              onclick: () => this.$bus.$emit('online-chat'),
            },
          },
          {
            title: "生产运营智慧化",
            description:
              "基于传统人工集装箱码头，完成岸桥、轨道桥、集卡自动化改造，研发首个智能解锁站，实现“自动化岸桥+自动化轨道桥+无人集卡编组+智能解锁站”联合作业。现对平行岸线智能化集装箱码头智能水平运输设备的精准调度和有序管理，全面支撑自动化岸桥、自动化轨道桥、智能水平运输设备、智能解锁站等智能化码头全设备要素的联合作业。",
            icon: require("@/asset/smart-port/ic_port_16.png"),
            btn: {
              label: "在线咨询",
              onclick: () => this.$bus.$emit('online-chat'),
            },
          },
          {
            title: "综合管理数字化",
            description:
              "加快推进企业数字化转型升级，研发企业综合管控系统，实现对人事、财务、资产、项目、决策等企业运营全要素的一体化管理，加强企业运营监管与风险防控。以实现“一港六区”船舶作业统一管控，研发智能调度指挥系统，实现船舶动态智能编排、进港车辆实时跟踪、生产效率智能分析，提高生产资源配置合理性，增加港口枢纽功能。",
            icon: require("@/asset/smart-port/ic_port_17.png"),
            btn: {
              label: "在线咨询",
              onclick: () => this.$bus.$emit('online-chat'),
            },
          },
          {
            title: "贸易物流数字化",
            description:
              "打造全港统一的对外服务平台，为客户提供7*24小时线上业务受理、集疏运预约、在线支付、金融保险、信息查询等便捷服务，打通进出口货物在港口物流环节的“最后一公里”，提升口岸通关效能。 优化港口业务单证流程，打造集约化管理平台，实现集装箱装箱单、设备交接单、进口货物提货单等单证“全程无纸化”。",
            icon: require("@/asset/smart-port/ic_port_18.png"),
            btn: {
              label: "在线咨询",
              onclick: () => this.$bus.$emit('online-chat'),
            },
          },
        ],
        this.globalData.smartPort.application.block2
      );
    },
  },
};
</script>
<style scoped lang="scss">
.container {
}
.structure {
  padding: 100px 0 80px;
  .card-box {
    margin-bottom: 80px;
  }
  .img {
    width: 100%;
    display: block;
    margin-top: 40px;
  }
}
.advantage {
  height: 764px;
  background: url(@/asset/smart-port/ic_port_10.png) no-repeat center/cover;
  text-align: center;
  overflow: hidden;
  .en {
    font-size: 36px;
    color: rgba(255, 255, 255, 0.4);
    margin-top: 80px;
  }
  .title {
    font-family: YouSheBiaoTiHei;
    margin-top: 12px;
    font-size: 36px;
    color: white;
  }
  .brief {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 34px;
  }
  .tabs {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.6);
    position: relative;
    margin-top: 80px;
    .item {
      text-align: center;
      font-size: 20px;
      color: white;
      padding-bottom: 40px;
      position: relative;
      cursor: pointer;
      .icon {
        width: 20px;
      }
      .txt {
        margin-top: 8px;
      }
    }
    .item-strip {
      position: absolute;
      top: 100%;
      left: 0;
      height: 4px;
      width: 33.3%;
      background: #4e99fd;
      margin-top: -1px;
      transition: all 0.5s;
    }
  }
  .content {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    margin-top: 40px;
    .img {
      width: 524px;
    }
    .right {
      text-align: left;
      .right-title {
        font-size: 28px;
        color: white;
        margin-bottom: 10px;
      }
      .right-brief {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 2;
      }
    }
  }
}
.application {
  padding: 80px 0;
  background: #f7fbff;
}
</style>
