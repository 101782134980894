<template>
  <div class="container">
    <top-nav :imgList="banner">
      <div class="banner-item-title">
        {{ globalData.smartPatrolResolution.title }}
      </div>
      <div class="banner-item-brief">
        {{ globalData.smartPatrolResolution.brief }}
      </div>
    </top-nav>
    <div class="box content-limit">
      <div class="item" v-for="(item, index) of box" :key="index">
        <img class="icon" :src="item.icon" alt="" />
        <div class="txt">{{ item.txt }}</div>
      </div>
    </div>
    <div class="resolution">
      <block-title
        :title="globalData.smartPatrolResolution.resolution.top.title"
        :en="globalData.smartPatrolResolution.resolution.top.en"
        :sub="globalData.smartPatrolResolution.resolution.top.sub"
      ></block-title>
      <img
        class="img content-limit"
        src="@/asset/smart-traffic/ic_traffic_50.png"
        alt=""
      />
    </div>
    <div class="scene">
      <block-title
        :title="globalData.smartPatrolResolution.scene.top.title"
        :en="globalData.smartPatrolResolution.scene.top.en"
        :sub="globalData.smartPatrolResolution.scene.top.sub"
      ></block-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of scene"
          :key="index"
          :style="{
            background: `linear-gradient(rgba(51, 51, 51, 0.4),rgba(51, 51, 51, 0.4)),url(${item.img}) no-repeat center/cover`,
          }"
        >
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
    </div>
    <div class="advantage content-limit">
      <block-title
        :title="globalData.smartPatrolResolution.advantage.top.title"
        :en="globalData.smartPatrolResolution.advantage.top.en"
        :sub="globalData.smartPatrolResolution.advantage.top.sub"
      ></block-title>
      <card-list
        :list="block3"
        orientation="y"
        style="margin-top: 80px"
      ></card-list>
    </div>
    <div class="structure">
      <block-title
        :title="globalData.smartPatrolResolution.structure.top.title"
        :en="globalData.smartPatrolResolution.structure.top.en"
        :sub="globalData.smartPatrolResolution.structure.top.sub"
      ></block-title>
      <img
        class="img content-limit"
        src="@/asset/smart-traffic/ic_traffic_57.webp"
        alt=""
      />
    </div>

    <div class="footer-resolution">
      <div class="block-content layout">
        <div class="left">
          <div class="f-title">
            {{ globalData.smartPatrolResolution.footer.title }}
          </div>
          <div class="f-en">
            {{ globalData.smartPatrolResolution.footer.en.toUpperCase() }}
          </div>
          <div class="f-sub">
            {{ globalData.smartPatrolResolution.footer.sub }}
          </div>
        </div>
        <div class="right">
          <div class="ask-btn">立即申请</div>
        </div>
      </div>
    </div>

    <my-footer></my-footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CardList from "../../components/card-list/index.vue";

export default {
  computed: {
    ...mapGetters(["globalData"]),
    box() {
      return window.deepMerge(
        [
          {
            icon: require("@/asset/smart-traffic/ic_traffic_46.png"),
            txt: "算法高效率",
          },
          {
            icon: require("@/asset/smart-traffic/ic_traffic_47.png"),
            txt: "可靠的准确率",
          },
          {
            icon: require("@/asset/smart-traffic/ic_traffic_48.png"),
            txt: "适配灵活性",
          },
          {
            icon: require("@/asset/smart-traffic/ic_traffic_49.png"),
            txt: "应用场景定制",
          },
        ],
        this.globalData.smartPatrolResolution.box
      );
    },
    scene() {
      return window.deepMerge(
        [
          {
            img: require("@/asset/smart-traffic/ic_traffic_56.png"),
            title: "道路裂缝",
            brief: "Road cracks",
          },
          {
            img: require("@/asset/smart-traffic/ic_traffic_51.png"),
            title: "道路龟裂",
            brief: "Road cracking",
          },
          {
            img: require("@/asset/smart-traffic/ic_traffic_52.png"),
            title: "道路沉降",
            brief: "Road settlement",
          },
          {
            img: require("@/asset/smart-traffic/ic_traffic_53.png"),
            title: "道路坑槽",
            brief: "Road potholes",
          },
          {
            img: require("@/asset/smart-traffic/ic_traffic_54.png"),
            title: "道路标识识别",
            brief: "Road sign recognition",
          },
          {
            img: require("@/asset/smart-traffic/ic_traffic_55.png"),
            title: "减速带异常识别",
            brief: "Abnormal recognition of speed bumps",
          },
        ],
        this.globalData.smartPatrolResolution.scene.list
      );
    },
    block3() {
      return window.deepMerge(
        [
          {
            title: "全国产化",
            description:
              "边缘计算主机的核心技术和处理器实现全国产化，采用高性能、低功耗、广泛兼容的 ARM 架构，保障处理大量数据时的高可用性",
            icon: require("@/asset/smart-traffic/ic_traffic_64.png"),
          },
          {
            title: "实时识别与定位",
            description:
              "边缘计算主机部署在巡检车上，对摄像头拍摄画面进行实时推理识别，并实时记录病害定位信息",
            icon: require("@/asset/smart-traffic/ic_traffic_59.png"),
          },
          {
            title: "断网运行",
            description:
              "支持无基站情况下断网道路巡检，待 5G 连接后，自动将缓存结果上传至云端",
            icon: require("@/asset/smart-traffic/ic_traffic_60.png"),
          },
          {
            title: "病害面积测算",
            description:
              "利用摄像头捕捉道路图像，并通过分析图像中的透视关系，计算出路面病害的实际尺寸和面积",
            icon: require("@/asset/smart-traffic/ic_traffic_61.png"),
          },
          {
            title: "巡查结果去重",
            description:
              "在一定范围内，对巡检数据进行全面分析和比对，识别出相似的病害情况，并做去重操作，从而减少重复的巡检结果",
            icon: require("@/asset/smart-traffic/ic_traffic_62.png"),
          },
          {
            title: "本地化部署",
            description:
              "道路智能巡检 EdgeAIoT 管理平台支持本地化部署，全面保障您的信息安全",
            icon: require("@/asset/smart-traffic/ic_traffic_63.png"),
          },
        ],
        this.globalData.smartPatrolResolution.advantage.block3
      );
    },
  },
  components: {
    CardList,
  },
  data() {
    return {
      banner: require("@/asset/smart-traffic/ic_traffic_01.png"),
    };
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 0 80px;
  .item {
    text-align: center;
    .icon {
      width: 65px;
      margin-bottom: 8px;
    }
    .txt {
      font-size: 24px;
      color: #333333;
    }
  }
}
.resolution {
  padding-bottom: 80px;
  .img {
    display: block;
    margin-top: 40px;
  }
}
.scene {
  padding: 80px 0 40px;
  background: url(@/asset/smart-traffic/ic_traffic_58.png) no-repeat
    center/cover;
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 80px;
    .item {
      width: 416px;
      height: 266px;
      text-align: center;
      .title {
        font-size: 24px;
        color: white;
        margin-top: 105px;
      }
      .brief {
        font-size: 24px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
.advantage {
  padding: 80px 0;
}
.structure {
  padding: 80px 0;
  background: url(@/asset/smart-traffic/ic_traffic_65.png) no-repeat
    center/cover;
  .img {
    display: block;
    margin-top: 40px;
  }
}
.footer-resolution {
  height: 304px;
  background-size: cover;

  .layout {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
  }
  .f-title {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 36px;
    color: #333333;
    margin-top: 60px;
  }
  .f-en {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 24px;
    color: #999999;
    margin-top: 10px;
  }
  .f-sub {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    margin-top: 40px;
  }
  .ask-btn {
    width: 200px;
    height: 60px;
    background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
    box-shadow: 3px 3px 8px 1px #e8e8e8;
    border-radius: 2px 2px 2px 2px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
}
</style>
