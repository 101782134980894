<template>
	<div class="cooperation-case">
		<top-nav :img-list="banner" :banner="[{}]" class="top-nav" isParent white>

			<div class="banner-item-title" style="text-align: center;z-index: 9999999;" v-html="static.banner.title">
			</div>
			<span class="banner-item-brief" style="width: 1280px;text-align: center"
				v-html="static.banner.brief"></span>
		</top-nav>
		<div class="banner-item-title" style="color: #fff;position: absolute;bottom: 186px;text-align: center;"
			v-html="static.banner.title"></div>
		<div class="banner-item-brief"
			style="width: 870px;text-align: center;position: absolute;bottom: 100px;left: 50%;margin-left: -435px;color: #fff;opacity: 0.6;"
			v-html="static.banner.brief"></div>
		<!-- <div class="banner-item-title" style="text-align: center;z-index: 9999999;" v-html="static.banner.title"></div> -->
		<div class="block-area pt-100px ">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<div style="display: flex;align-items: center;justify-content: center;margin-top: 80px;position: relative;">
					<div style="position: relative;" @click="changeVideoStatus1">
						<video objectFit=“cover” src="/static-images/tj (1).mp4"  ref="video1" style="width: 627px;height: 418px;mix-blend-mode: screen; display: block"
							@play="videoIsPlay1 = true;" @pause="videoIsPlay1 = false;" @ended="videoIsPlay1 = false;"
							loop></video>
						<div class="msks" :style="{ background: b1 }"></div>
						<div class="video-img" v-show="!videoIsPlay1" >
							<img src="/static-images/ic_index_78.png" style="width: 100%;height: 100%;"/>
						</div>
						<div class="video-title">
							<div style="margin-top: 24px;margin-left: 24px;">
								烟火映星辰，所愿皆能成拥抱达沃斯，感受新天津
							</div>
							<div style="margin-top: 10px;margin-left: 24px;font-size: 16px;opacity: 0.6;">
								无人机编队掀起了灯光艺术表演的新潮流
							</div>
						</div>
					</div>
					<div style="position: relative;margin-left: 26px;" @click="changeVideoStatus2">
						<video src="/static-images/tj (2).mp4" ref="video2" style="width: 627px;height: 418px;"
							@play="videoIsPlay2 = true;" @pause="videoIsPlay2 = false;" @ended="videoIsPlay2 = false;"
							loop></video>
						<div class="msks" :style="{ background: b2 }"></div>
						<div class="video-img" v-show="!videoIsPlay2">
							<img src="/static-images/ic_index_78.png" />
						</div>
						<div class="video-title">
							<div style="margin-top: 24px;margin-left: 24px;">
								天塔达沃斯
							</div>
							<div style="margin-top: 10px;margin-left: 24px;font-size: 16px;opacity: 0.6;">
								无人机编队掀起了灯光艺术表演的新潮流
							</div>
						</div>
					</div>
				</div>

				<div style="display: flex;align-items: center;justify-content: center;margin-top: 26px">
					<div style="position: relative;" @click="changeVideoStatus3">
						<video src="/static-images/tj (3).mp4" ref="video3" style="width: 627px;height: 418px;"
							@play="videoIsPlay3 = true;" @pause="videoIsPlay3 = false;" @ended="videoIsPlay3 = false;"
							loop></video>
						<div class="msks" :style="{ background: b3 }"></div>
						<div class="video-img" v-show="!videoIsPlay3">
							<img src="/static-images/ic_index_78.png" />
						</div>
						<div class="video-title">
							<div style="margin-top: 24px;margin-left: 24px;">
								航母公园
							</div>
							<div style="margin-top: 10px;margin-left: 24px;font-size: 16px;opacity: 0.6;">
								无人机编队掀起了灯光艺术表演的新潮流
							</div>
						</div>
					</div>
					<div style="position: relative;margin-left: 26px;" @click="changeVideoStatus4">
						<video src="/static-images/tj (4).mp4" ref="video4" style="width: 627px;height: 418px;"
							@play="videoIsPlay4 = true;" @pause="videoIsPlay4 = false;" @ended="videoIsPlay4 = false;"
							loop></video>
						<div class="msks" :style="{ background: b4 }"></div>
						<div class="video-img" v-show="!videoIsPlay4">
							<img src="/static-images/ic_index_78.png" />
						</div>
						<div class="video-title">
							<div style="margin-top: 24px;margin-left: 24px;">
								天津河西文化中心西岸文化消费季
							</div>
							<div style="margin-top: 10px;margin-left: 24px;font-size: 16px;opacity: 0.6;">
								无人机编队掀起了灯光艺术表演的新潮流
							</div>
						</div>
					</div>
				</div>

				<div style="display: flex;align-items: center;justify-content: center;margin-top: 26px">
					<div style="position: relative;" @click="changeVideoStatus5">
						<video src="/static-images/tj (5).mp4" ref="video5" style="width: 627px;height: 418px;" poster="/static-images/zg-100.png"
							@play="videoIsPlay5 = true;" @pause="videoIsPlay5 = false;" @ended="videoIsPlay5 = false;"
							loop></video>
						<div class="msks" :style="{ background: b5 }"></div>
						<div class="video-img" v-show="!videoIsPlay5">
							<img src="/static-images/ic_index_78.png" />
						</div>
						<div class="video-title">
							<div style="margin-top: 24px;margin-left: 24px;">
								焰绘航母活力河西、品质之城佛山美的无人机灯光秀
							</div>
							<div style="margin-top: 10px;margin-left: 24px;font-size: 16px;opacity: 0.6;">
								无人机编队掀起了灯光艺术表演的新潮流
							</div>
						</div>
					</div>
					<div style="position: relative;margin-left: 26px;" @click="changeVideoStatus6">
						<video src="/static-images/tj (6).mp4" ref="video6" style="width: 627px;height: 418px;" poster="/static-images/zg-100.png"
							@play="videoIsPlay6 = true;" @pause="videoIsPlay6 = false;" @ended="videoIsPlay6 = false;"
							loop></video>
						<div class="msks" :style="{ background: b6 }"></div>
						<div class="video-img" v-show="!videoIsPlay6">
							<img src="/static-images/ic_index_78.png" />
						</div>
						<div class="video-title">
							<div style="margin-top: 24px;margin-left: 24px;">
								京津明珠，新福武清千架无人机点亮丽水景宁畲族...
							</div>
							<div style="margin-top: 10px;margin-left: 24px;font-size: 16px;opacity: 0.6;">
								无人机编队掀起了灯光艺术表演的新潮流
							</div>
						</div>
					</div>
				</div>

				<div style="display: flex;align-items: center;justify-content: center;margin-top: 26px">
					<div style="position: relative;" @click="changeVideoStatus7">
						<video src="/static-images/tj (7).mp4" ref="video7" style="width: 627px;height: 418px;" poster="/static-images/zg-100.png"
							@play="videoIsPlay7 = true;" @pause="videoIsPlay7 = false;" @ended="videoIsPlay7 = false;"
							loop></video>
						<div class="msks" :style="{ background: b7 }"></div>
						<div class="video-img" v-show="!videoIsPlay7"  >
							<img src="/static-images/ic_index_78.png" />
						</div>
						<div class="video-title">
							<div style="margin-top: 24px;margin-left: 24px;">
								浙江丽水景宁畲族自治县
							</div>
							<div style="margin-top: 10px;margin-left: 24px;font-size: 16px;opacity: 0.6;">
								无人机编队掀起了灯光艺术表演的新潮流
							</div>
						</div>
					</div>
					<div style="position: relative;margin-left: 26px;" @click="changeVideoStatus8">
						<video src="/static-images/tj (8).mp4" ref="video8" style="width: 627px;height: 418px;"
							@play="videoIsPlay8 = true;" @pause="videoIsPlay8 = false;" @ended="videoIsPlay8 = false;"
							loop></video>
						<div class="msks" :style="{ background: b8 }"></div>
						<div class="video-img" v-show="!videoIsPlay8">
							<img src="/static-images/ic_index_78.png" />
						</div>
						<div class="video-title">
							<div style="margin-top: 24px;margin-left: 24px;">
								失落王国的奇幻夜无人机点亮艾迪逊除夕夜
							</div>
							<div style="margin-top: 10px;margin-left: 24px;font-size: 16px;opacity: 0.6;">
								无人机编队掀起了灯光艺术表演的新潮流
							</div>
						</div>
					</div>
				</div>

				<div style="display: flex;align-items: center;margin-top: 26px">
					<div style="position: relative;" @click="changeVideoStatus9">
						<video src="/static-images/tj (9).mp4" ref="video9" style="width: 627px;height: 418px;"
							@play="videoIsPlay9 = true;" @pause="videoIsPlay9 = false;" @ended="videoIsPlay9 = false;"
							loop></video>
						<div class="msks" :style="{ background: b9 }"></div>
						<div class="video-img" v-show="!videoIsPlay9">
							<img src="/static-images/ic_index_78.png" />
						</div>
						<div class="video-title">
							<div style="margin-top: 24px;margin-left: 24px;">
								艾迪逊
							</div>
							<div style="margin-top: 10px;margin-left: 24px;font-size: 16px;opacity: 0.6;">
								无人机编队掀起了灯光艺术表演的新潮流
							</div>
						</div>
					</div>
					
				</div>
			
			</div>
		</div>
		<!-- <div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<div class="cd-list" style="margin-top: 80px;">
					<div class="cd-item" v-for="(t, i) in block2" :key="i">
						<img :src="t.icon" alt="">
						<div class="context">
							<div class="c-title">{{ t.title }}</div>
							<div class="c-description-inline">{{ t.description }}</div>
							<div class="c-description">{{ t.description }}</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<!-- <div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<case-list :list="block3"></case-list>
			</div>
		</div> -->
		<div class="hover-pop-success" v-if="showing">
			<div class="private-back" @click="closeDetail"></div>
			<div class="private-content" :style="{
				background: `url('/static-images/prompt_bg.png') no-repeat center/cover`,
			}">
				<div class="private-title">
					{{ showingDetail.title }}
					<img @click="closeDetail" class="private-close success-close" src="/static-images/ic_close.png" />
				</div>
				<div style="object-fit: fill;width: 100%;height: 100%;">
					<video :src="showingDetail.url" ref="video9" controls style="height: 100%;object-fit: cover;width: 100%;"
							@play="videoIsPlay9 = true;" @pause="videoIsPlay9 = false;" @ended="videoIsPlay9 = false;"
							></video>
				</div>
				<!-- <div class="pd-name">{{ showingDetail.name }}</div>
				<div class="pd-content">
					<div class="pd-left">
						<img :src="showingDetail.img" class="pd-img" alt="" />
					</div>
					<div class="pd-right">
						<div class="pd-brief">{{ showingDetail.brief }}</div>
						<div class="pd-feature" v-html="showingDetail.feature"></div>
					</div>
				</div> -->
				<!-- <div class="pd-buttons" style="margin: 0 auto;">
					<div class="private-button" @click="closeDetail">确定</div>
				</div> -->
			</div>
		</div>
		<customer-wall></customer-wall>
		<my-footer></my-footer>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CardList from "../../components/card-list/index.vue";

export default {
	name: "cooperation-case",
	components: { CardList },
	data: function () {
		return {
			showingDetail: {},
			showing: false,
			videoIsPlay1: false,
			videoIsPlay2: false,
			videoIsPlay3: false,
			videoIsPlay4: false,
			videoIsPlay5: false,
			videoIsPlay6: false,
			videoIsPlay7: false,
			videoIsPlay8: false,
			videoIsPlay9: false,
			showCase: true,
			b1: "",
			b2: "",
			b3: "",
			b4: "",
			b5: "",
			b6: "",
			b7: "",
			b8: "",
			b9: "",
			banner: ['https://www.efy-tech.com/file/upload/2021/03/18/1617027400.mp4'],
			defines: {
				block1: [
					{
						title: '自有研发团队',
						description: '我们拥有丰富的行业经验和专业知识，能够迅速应对市场变化和客户需求，提供高效、可靠的技术解决方案。',
						icon: require('./assets/cc-b1-0.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(193)
						}
					},
					{
						title: '产品丰富',
						description: '提供智能领域的设备、数据、平台、应用，具备丰富的产线、产量支持和完善的售后服务通道。',
						icon: require('./assets/cc-b1-1.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(193)
						}
					},
					{
						title: '营销体系健全',
						description: '形成了“品牌+研发+生产+渠道”完整链条，拥有成熟的销售体系。',
						icon: require('./assets/cc-b1-2.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(193)
						}
					},
					{
						title: '运维服务高效',
						description: '提供7X24小时的机器人后台管控、运输调度、保养与维修服务，降低自主运维的成本。',
						icon: require('./assets/cc-b1-3.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(193)
						}
					},
				],
				block2: [
					{
						title: '水电站北斗高精度变形监测项目',
						icon: require('./assets/cc-b2-0.png'),
						description: '本项目基于北斗卫星导航系统的高精度变形监测技术应用，形成一套“平战结合、有效管用、稳定可靠、经济便捷”的北斗高精度监测系统，水平和垂直位移精度分别达到2mm和3mm，实现全天候、不间断、高精度变形监测，满足水电站大坝运行安全监督管理应用要求。'
					},
					{
						title: '危化车车辆监控项目',
						icon: require('./assets/cc-b2-1.png'),
						description: '利用北斗高精度定位技术，同时结合GIS、车载视频智能分析以及电子铅封锁等技术，实现对危化车运输全程位置的精准跟踪，有效降低危化品运输安全风险。'
					},
					{
						title: '智慧桥梁安全监测',
						icon: require('./assets/cc-b2-2.png'),
						description: '2020年10月，云凤项目已发布了蓝色预警、黄色预警和橙色预警，“得到了相关主管部门及业主单位的重视，达到了监测目的，对现场的实施处置起到数据支撑、监测验证作用。'
					},
					{
						title: '数字孪生智慧矿山',
						icon: require('./assets/cc-b2-3.png'),
						description: '通过在隐患点布设一体化智能监测站，对隐患灾体的地表变形、深部变形地表裂缝、地下水位、降雨量等进行实时监测，在智能网关进行边缘计算后，将数据上传云平台，进行远程在线实时地灾监测、分析和预警。'
					},
					{
						title: '云凤高速K36边坡监测',
						icon: require('./assets/cc-b2-4.png'),
						description: '以北斗高精度定位和时空智能技术为核心，融合云计算、智能物联网、高精度地图等技术，建立适用桥梁的综合性安全监测平台，对于桥梁监测做到信息化管理、数字化监测、智能化分析。'
					},
					{
						title: '昆明经开区国土局地灾隐患点监测',
						icon: require('./assets/cc-b2-5.png'),
						description: '融合云计算、大数据、物联网、地理信息系统、数字孪生等技术，整合矿山区域的信息资源，按照安防、车辆、能源和应急等业务一体化设计理念，实现多方位全覆盖、动态在线精准监管、信息资源实时共享。以“一张图”为载体，将矿山区域的安全、环境状况和能源消耗等进行综合展示，强化矿山区域安全运维保障能力，促进矿山区域良性可持续发展。'
					},
					{
						title: '石坝社区小石坝崩塌',
						icon: require('./assets/cc-b2-6.png'),
						description: '灭失矿山原采石坑监测'
					},
					{
						title: '昆明经开区',
						icon: require('./assets/cc-b2-7.png'),
						description: '小石牛采矿区灭失矿山监测'
					},
				],
				block3: [
					{
						name: '智慧水利',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-water-conservancy'),
						img: require('./assets/cc-b3-0.png')
					},
					{
						name: '智慧矿山',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-quarry'),
						img: require('./assets/cc-b3-1.png')
					},
					{
						name: '智慧港口',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-port'),
						img: require('./assets/cc-b3-2.png')
					},
					{
						name: '智慧交通',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-traffic'),
						img: require('./assets/cc-b3-3.png')
					},
					{
						name: '智慧城市',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-city'),
						img: require('./assets/cc-b3-4.png')
					},
					{
						name: '智慧桥梁',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-city/bridge-monitor'),
						img: require('./assets/cc-b3-5.png')
					},
				]
			}
		}
	},
	mounted() {

	},
	methods: {
		closeDetail() {
			this.showing = false;
			document.body.style.overflow = "auto";
		},
		restartVideo() {
			this.$refs.videoPlayer.currentTime = 0;
			this.$refs.videoPlayer.play();
		},
		changeVideoStatus1() {
			this.showingDetail.title="烟火映星辰，所愿皆能成拥抱达沃斯，感受新天津"
			this.showingDetail.url='/static-images/tj (1).mp4'
			this.showing=true
			const video = this.$refs.video1
			if (video.paused) {
				video.play()
				this.b1 = "transparent"
			} else {
				video.pause()
				this.b1 = "#333"
			}
		},
		changeVideoStatus2() {
			this.showingDetail.title="天塔达沃斯"
			this.showingDetail.url='/static-images/tj (2).mp4'
			this.showing=true
			const video = this.$refs.video2
			if (video.paused) {
				video.play()
				this.b2 = "transparent"
			} else {
				video.pause()
				this.b2 = "#333"
			}
		},
		changeVideoStatus3() {
			this.showingDetail.title="航母公园"
			this.showingDetail.url='/static-images/tj (3).mp4'
			this.showing=true
			const video = this.$refs.video3
			if (video.paused) {
				video.play()
				this.b3 = "transparent"
			} else {
				video.pause()
				this.b3 = "#333"
			}
		},
		changeVideoStatus4() {
			this.showingDetail.title="天津河西文化中心西岸文化消费季"
			this.showingDetail.url='/static-images/tj (4).mp4'
			this.showing=true
			const video = this.$refs.video4
			if (video.paused) {
				video.play()
				this.b4 = "transparent"
			} else {
				video.pause()
				this.b4 = "#333"
			}
		},
		changeVideoStatus5() {
			this.showingDetail.title="焰绘航母活力河西、品质之城佛山美的无人机灯光秀"
			this.showingDetail.url='/static-images/tj (5).mp4'
			this.showing=true
			const video = this.$refs.video5
			if (video.paused) {
				video.play()
				this.b5 = "transparent"
			} else {
				video.pause()
				this.b5 = "#333"
			}
		},
		changeVideoStatus6() {
			this.showingDetail.title="京津明珠，新福武清千架无人机点亮丽水景宁畲族..."
			this.showingDetail.url='/static-images/tj (6).mp4'
			this.showing=true
			const video = this.$refs.video6
			if (video.paused) {
				video.play()
				this.b6 = "transparent"
			} else {
				video.pause()
				this.b6 = "#333"
			}
		},
		changeVideoStatus7() {
			this.showingDetail.title="浙江丽水景宁畲族自治县"
			this.showingDetail.url='/static-images/tj (7).mp4'
			this.showing=true
			const video = this.$refs.video7
			if (video.paused) {
				video.play()
				this.b7 = "transparent"
			} else {
				video.pause()
				this.b7 = "#333"
			}
		},
		changeVideoStatus8() {
			this.showingDetail.title="失落王国的奇幻夜无人机点亮艾迪逊除夕夜"
			this.showingDetail.url='/static-images/tj (8).mp4'
			this.showing=true
			const video = this.$refs.video8
			if (video.paused) {
				video.play()
				this.b8 = "transparent"
			} else {
				video.pause()
				this.b8 = "#333"
			}
		},
		changeVideoStatus9() {
			this.showingDetail.title="艾迪逊"
			this.showingDetail.url='/static-images/tj (9).mp4'
			this.showing=true
			const video = this.$refs.video9
			if (video.paused) {
				video.play()
				this.b9 = "transparent"
			} else {
				video.pause()
				this.b9 = "#333"
			}
		},
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['cooperation-case']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
	},
}
</script>

<style scoped lang="scss">
.privateBox,
.hover-pop-success {
	.private-back {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba($color: #000000, $alpha: 0.6);
		z-index: 9999;
	}

	.private-content {
		position: fixed;
		width: 1280px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 99999;

		.private-title {
			width: 100%;
			// width: 1280px;
			color: white;
			font-size: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 60px;
			background: rgba(255,255,255,0.1);
			
			position: absolute;
			z-index: 99999;
			.private-close {
				position: absolute;
				right: 24px;
				top: 22px;
				width: 16px;
				cursor: pointer;
			}
		}

		.private-button {
			width: 157px;
			height: 48px;
			background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
			border-radius: 2px 2px 2px 2px;
			font-size: 20px;
			color: #000;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}
}

video {
	object-fit: cover;
	// mix-blend-mode: screen;
	 display: block;
	// object-fit: contain;
	// background: #000 !important;
	// opacity: 1;
}

video:focus {
outline:none;
}

.msks {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #333;
	opacity: 0.4;
}

.video-img {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 44% !important;
	left: 50%;
	margin-left: -20px;
	margin-bottom: 200px;
}


.al-video {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	object-fit: cover;
}

::v-deep(.now-button) {
	display: none
}

.use-bg {
	background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
	background-size: cover;
}

.cd-list {
	display: grid;
	grid-template-columns: repeat(2, 620px);
	justify-content: space-between;
	grid-auto-rows: 480px;
	row-gap: 40px;

	.cd-item {
		position: relative;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.cd-item:hover .context {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: rgba(78, 153, 253, .8);

		.c-description-inline {
			display: none;
		}

		.c-title {
			color: white;
		}

		.c-description {
			color: white;
			display: block;
		}
	}

	.context {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 104px;
		width: 100%;
		overflow: hidden;
		background: rgba(white, 0.8);
		border-radius: 2px 2px 2px 2px;
		padding: 24px 0;
		box-sizing: border-box;
		transition: all .2s;

		.c-title {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 500;
			font-size: 24px;
			color: #333333;
			white-space: nowrap;
			padding: 0 24px;
			box-sizing: border-box;
			transition: all .2s;
		}

		.c-description-inline,
		.c-description {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: #666666;
			padding: 0 24px;
			box-sizing: border-box;
			margin-top: 16px;
			transition: all .2s;
		}

		.c-description-inline {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.c-description {
			display: none;
		}
	}
}
</style>
