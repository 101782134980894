<template>
  <div class="container">
    <top-nav :imgList="banner">
      <div class="banner-item-title">
        {{ globalData.smartLocationResolution.title }}
      </div>
      <div class="banner-item-brief" v-html="globalData.smartLocationResolution.brief"></div>
    </top-nav>
    <div class="box content-limit">
      <card-list :list="block3"></card-list>
    </div>
    <div class="structure">
      <block-title
        :title="globalData.smartLocationResolution.structure.top.title"
        :en="globalData.smartLocationResolution.structure.top.en"
        :sub="globalData.smartLocationResolution.structure.top.sub"
      ></block-title>
      <img
        class="content-limit img"
        src="@/asset/smart-port/ic_port_39.png"
        alt=""
      />
    </div>
    <div class="core">
      <block-title
        :title="globalData.smartLocationResolution.core.top.title"
        :en="globalData.smartLocationResolution.core.top.en"
        :sub="globalData.smartLocationResolution.core.top.sub"
      ></block-title>
      <div class="content-limit">
        <card-list :list="block4" style="margin-top: 80px"></card-list>
      </div>
    </div>
    <div class="value">
      <div class="value-title">
        {{ globalData.smartLocationResolution.value.title }}
      </div>
      <div class="value-en">
        {{ globalData.smartLocationResolution.value.en }}
      </div>
      <div class="value-brief">
        {{ globalData.smartLocationResolution.value.brief }}
      </div>
      <div class="list content-limit">
        <div class="item" v-for="(item, index) of value" :key="index">
          <img class="img" :src="item.img" alt="" />
          <div class="txt">
            <div class="title">{{ item.title }}</div>
            <div class="brief">
              {{ item.brief }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="platform">
      <block-title
        :title="globalData.smartLocationResolution.platform.top.title"
        :en="globalData.smartLocationResolution.platform.top.en"
        :sub="globalData.smartLocationResolution.platform.top.sub"
      ></block-title>
      <img
        class="img content-limit"
        src="@/asset/smart-port/ic_port_40.png"
        alt=""
      />
    </div>
    <div class="case">
      <block-title
        :title="globalData.smartLocationResolution.case.top.title"
        :en="globalData.smartLocationResolution.case.top.en"
        :sub="globalData.smartLocationResolution.case.top.sub"
      ></block-title>
      <case-list :list="cases"></case-list>
    </div>

    <div class="footer-resolution">
      <div class="block-content layout">
        <div class="left">
          <div class="f-title">
            {{ globalData.smartLocationResolution.footer.title }}
          </div>
          <div class="f-en">
            {{ globalData.smartLocationResolution.footer.en.toUpperCase() }}
          </div>
          <div class="f-sub">
            {{ globalData.smartLocationResolution.footer.sub }}
          </div>
        </div>
        <div class="right">
          <div class="ask-btn">在线咨询</div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>
    <script>
import { mapGetters } from "vuex";
import PinCard from "../../components/card-list/pin-card.vue";
import DisplayList from "../../components/display-list/index.vue";
import CardList from "../../components/card-list/index.vue";
import IntroduceList from "../../components/introduce-list/index.vue";

export default {
  data() {
    return {
      banner: require("@/asset/smart-port/ic_port_11.png"),
    };
  },
  components: {
    PinCard,
    DisplayList,
    CardList,
    IntroduceList,
  },
  computed: {
    ...mapGetters(["globalData"]),
    block3() {
      return window.deepMerge(
        [
          {
            title: "安全监测",
            description:
              "作业人员位置监测解决方案通过在软件平台可以查看人员实时高精度位置，对于禁区闯入、突发事件等进行应急处理",
            icon: require("@/asset/smart-port/ic_port_55.png"),
          },
          {
            title: "综合分析",
            description:
              "通过结合人工智能和大数据技术，通过历史数据和实时数据，可以预测人员可能到达的位置，从而提前做出相应的决策。",
            icon: require("@/asset/smart-port/ic_port_53.png"),
          },
          {
            title: "智能管理",
            description:
              "作业人员位置监测解决方案基于准确的位置信息，可以实现人员的智能打卡、工单派发、工时统计等，提升管理水平",
            icon: require("@/asset/smart-port/ic_port_54.png"),
          },
        ],
        this.globalData.smartLocationResolution.box.block3
      );
    },
    block4() {
      return window.deepMerge(
        [
          {
            title: "高精度实时位置查看",
            description: "可实时查看精确到亚米级的人员，实现快速的精确追踪",
            icon: require("@/asset/smart-port/ic_port_42.png"),
          },
          {
            title: "高精地图可视化",
            description:
              "定制开发带有业务场景的高精度专属地图，快速加载并与公共地图无缝拼接",
            icon: require("@/asset/smart-port/ic_port_43.png"),
          },
          {
            title: "人员行动轨迹查询",
            description:
              "支持人员行动轨迹在高精度地图上的可视化展示，真实还原人员的行动轨迹",
            icon: require("@/asset/smart-port/ic_port_44.png"),
          },
          {
            title: "禁入区域管理",
            description:
              "其于电子围栏技术，设置禁入区域、作业区域，可支持闯入报警、靠近报警、超时作业等",
            icon: require("@/asset/smart-port/ic_port_45.png"),
          },
          {
            title: "视频联动",
            description:
              "接入现场视频监控，实现定位与视频联动，便于及时掌握现场状况",
            icon: require("@/asset/smart-port/ic_port_46.png"),
          },
          {
            title: "智能告警",
            description:
              "sos一键呼救、禁区报警、轨迹纠偏等情况及时响应，快速处理",
            icon: require("@/asset/smart-port/ic_port_47.png"),
          },
        ],
        this.globalData.smartLocationResolution.core.block4
      );
    },
    value() {
      return window.deepMerge(
        [
          {
            img: require("@/asset/smart-port/ic_port_49.png"),
          },
          {
            img: require("@/asset/smart-port/ic_port_51.png"),
          },
          {
            img: require("@/asset/smart-port/ic_port_50.png"),
          },
        ],
        this.globalData.smartLocationResolution.value.list
      );
    },
    cases() {
      return window.deepMerge(
        [
          {
            name: "智慧水利",
            img: require("@/asset/smart-port/ic_port_38.png"),
            button: "了解更多",
	          onClick: () => this.$router.push('/smart-water-conservancy'),
          },
          {
            name: "智慧矿山",
            img: require("@/asset/smart-port/ic_port_34.png"),
            button: "了解更多",
	          onClick: () => this.$router.push('/smart-quarry'),
          },
          {
            name: "智慧港口",
            img: require("@/asset/smart-port/ic_port_36.png"),
            button: "了解更多",
	          onClick: () => this.$router.push('/smart-port'),
          },
          {
            name: "智慧交通",
            img: require("@/asset/smart-port/ic_port_33.png"),
            button: "了解更多",
	          onClick: () => this.$router.push('/smart-traffic'),
          },
          {
            name: "智慧城市",
            img: require("@/asset/smart-port/ic_port_35.png"),
            button: "了解更多",
	          onClick: () => this.$router.push('/smart-city'),
          },
          {
            name: "智慧桥梁",
            img: require("@/asset/smart-port/ic_port_37.png"),
            button: "了解更多",
	          onClick: () => this.$router.push('/smart-city/bridge-monitor'),
          },
        ],
        this.globalData.smartLocationResolution.case.list
      );
    },
  },
};
</script>
    <style scoped lang="scss">
.container {
}
.box {
  padding: 100px 0 80px;
}
.structure {
  padding: 80px 0 40px;
  background: url(@/asset/smart-port/ic_port_48.png) center/cover no-repeat;
  .img {
    display: block;
    margin-top: 40px;
  }
}
.core {
  padding: 80px 0;
}
.value {
  padding: 80px 0 40px;
  background: url(@/asset/smart-port/ic_port_52.png) center/cover no-repeat;
  .value-title {
    font-family: YouSheBiaoTiHei;
    font-size: 36px;
    color: white;
    margin-bottom: 13px;
    text-align: center;
  }
  .value-en {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 40px;
    text-align: center;
  }
  .value-brief {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 80px;
    text-align: center;
  }
  .list {
    display: flex;
    gap: 106px;
    justify-content: center;
    .item {
      width: 356px;
      background: white;
      .img {
        width: 100%;
      }
      .txt {
        padding: 40px;
        text-align: center;
        .title {
          font-size: 24px;
          color: #333333;
        }
        .brief {
          font-size: 16px;
          color: #666666;
          line-height: 2;
          margin-top: 12px;
        }
      }
    }
  }
}
.platform {
  padding: 80px 0;

  .img {
    width: 100%;
    margin-top: 40px;
    display: block;
  }
}
.case {
  padding: 80px 0 40px;
  background: url(@/asset/smart-port/ic_port_41.png) center/cover no-repeat;
}
.footer-resolution {
  height: 304px;
  background-size: cover;

  .layout {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
  }
  .f-title {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 36px;
    color: #333333;
    margin-top: 60px;
  }
  .f-en {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 24px;
    color: #999999;
    margin-top: 10px;
  }
  .f-sub {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    margin-top: 40px;
  }
  .ask-btn {
    width: 200px;
    height: 60px;
    background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
    box-shadow: 3px 3px 8px 1px #e8e8e8;
    border-radius: 2px 2px 2px 2px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
}
</style>
