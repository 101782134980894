<template>
	<div class="security-monitoring">
		<top-nav :img-list="banner">
			<div class="banner-item-title" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px; text-align: left" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list :list="block1" style="margin-top: 80px;"></card-list>
			</div>
		</div>
		<div class="block-area sm-b2 pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<img src="./assets/sm-b3.png" style="margin-top: 45px;" alt="">
			</div>
		</div>
		<div class="block-area sm-b3 pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<img src="./assets/sm-b4.png" style="margin-top: 40px;" alt="">
				<card-list :list="block2" style="margin-top: 40px;"></card-list>
			</div>
		</div>
		<div class="block-area sm-b6 pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title4"></block-title>
				<img src="./assets/sm-b6.png" style="margin-top: 40px;" alt="">
			</div>
		</div>
		<div class="block-area sm-b8 pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title5"></block-title>
				<img src="./assets/sm-b7.png" style="margin-top: 40px;" alt="">
			</div>
		</div>

		<footer-resolution :data="static.title6"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CardList from "@/components/card-list/index.vue";

export default {
	name: "security-monitoring",
	components: {
		CardList
	},
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '北斗/GNSS云端一体安全监测技术',
						description: '利用北斗卫星导航系统的高精度定位、测速和授时功能，以及GNSS系统的全球覆盖和抗干扰性能，实现高精度、高可靠性的安全监测。',
						icon: require('./assets/sm-b1-0.png'),
					},
					{
						title: '自研中小型水库监测管理平台',
						description: '该平台基于先进的物联网和大数据技术，集成了水库水位、降雨量、水质等各项监测数据的采集、传输、处理、存储和展示等功能，为水库管理提供了全面的解决方案。',
						icon: require('./assets/sm-b1-1.png'),
					},
					{
						title: '视频联动预警',
						description: '视频联动预警是一种先进的安全防范技术，它通过将视频监控系统与其他安全设备或系统进行联动，实现对安全事件的实时监测和预警。',
						icon: require('./assets/sm-b1-2.png'),
					},
					{
						title: '自研北斗三号短报文通信技术',
						description: '该技术利用北斗三号卫星导航系统的信号传输功能，实现了在野外、海洋等无移动通信信号覆盖区域进行短报文通信的功能。',
						icon: require('./assets/sm-b1-3.png'),
						style: {
							height: '304px'
						}
					},
					{
						title: '地形采集综合测绘服务',
						description: '该服务的主要内容涵盖了地形测量、工程测量、土地资源调查、城市规划测量等方面，利用先进的测绘技术和设备，对地形地貌、自然资源、基础设施等目标进行数据采集、处理、存储和展示。',
						icon: require('./assets/sm-b1-4.png'),
						style: {
							height: '304px'
						}
					},
				],
				block2: [
					{
						title: '数据采集',
						description: '数据采集单元对渗流、渗压等感知数据进行实时采集，并按水文规约要求将数据上传到数据接收系统。',
						icon: require('./assets/sm-b5-0.png'),
					},
					{
						title: '数据监测联动',
						description: '遥测终端可接入雨量、水位、视频图像、流量、预警广播等监测预警设备，汇总数据采集单元数据流，并与本地视频采集、存 储设备进行联动实现监测预警设备联动控制、监测数据实时上传。',
						icon: require('./assets/sm-b5-1.png'),
					},
					{
						title: '数据互联互通',
						description: '监测数据采用一站多发方式，同时向省级数据中心、市县级分中心发送数据，实现各级监测数据互联互通，确保数据传输及时可靠。',
						icon: require('./assets/sm-b5-2.png'),
					},
				]
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-water']['security-monitoring']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		banner() {
			return require('./assets/security-monitor-banner.png')
		},
	}
}
</script>

<style scoped lang="scss">
.security-monitoring {
	.sm-b2 {
		background: url("./assets/sm-b2.png") center no-repeat;
		background-size: 100% 100%;
		img {
			object-fit: scale-down;
			margin: 0 auto;
			display: block;
			width: 100%;
			height: 750px;
		}
	}
	.sm-b3 {
		img {
			object-fit: scale-down;
			margin: 0 auto;
			display: block;
			width: 100%;
			height: 711px;
		}
	}
	.sm-b6 {
		background: url("./assets/sm-b2.png") center no-repeat;
		background-size: 100% 100%;
		img {
			object-fit: scale-down;
			margin: 0 auto;
			display: block;
			width: 100%;
			height: 736px;
		}
	}
	.sm-b8 {
		img {
			object-fit: scale-down;
			margin: 0 auto;
			display: block;
			width: 100%;
			height: 995px;
		}
	}
}
</style>
