<template>
	<div class="block-title">
		<div class="title">{{ renderInfo.title }}</div>
		<div class="sub-title-en">{{ renderInfo.en.toUpperCase() }}</div>
		<div class="sub-title">{{ renderInfo.sub }}</div>
	</div>
</template>

<script>
export default {
	name: "index",
	props: {
		title: {
			type: String,
			default: ""
		},
		en: {
			type: String,
			default: ""
		},
		sub: {
			type: String,
			default: ""
		},
		data: {
			type: [Object, undefined],
		}
	},
	computed: {
		renderInfo() {
			return this.data || {
				title: this.title,
				en: this.en,
				sub: this.sub
			}
		}
	}
}
</script>

<style scoped lang="scss">
.block-title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 60px;
	.title {
		font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
		font-weight: 400;
		font-size: 36px;
		color: #333333;
	}
	.sub-title-en {
		
		// font-family: AlibabaPuHuiTi-3-55-Regular, serif;
		font-weight: 500;
		font-size: 26px;
		color: #999999;
		margin-top: 13px;
	}
	.sub-title {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 24px;
		color: #666666;
		margin-top: 40px;
	}
}
</style>
