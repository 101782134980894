<template>
	<div class="quarry-monitor">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: left;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list :list="block1" gap="24" style="margin-top: 80px;"></card-list>
				<card-item style="margin-top: 24px;" :title="block2.title"
				           :description="block2.description"
				           :icon="block2.icon"
				           :content-width="block2.contentWidth"
				           :layout="block2.layout"
				           :style="block2.style"></card-item>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<img class="b3-img" style="margin-top: 40px;" src="./assets/qm-b3.png" alt="">
			</div>
		</div>
		<introduce-carousel style="height: 730px" :list="block3.list" :title="block3.title" :en="block3.en" :sub="block3.sub"></introduce-carousel>
		<footer-resolution style="background-image: none" :data="static.title3"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import CardList from "../../components/card-list/index.vue";
import CardItem from "../../components/card-list/card-item.vue";
import IntroduceCarousel from "../../components/introduce-list/introduce-carousel.vue";
import { mapGetters } from "vuex";

export default {
	name: "quarry-monitor",
	components: { IntroduceCarousel, CardItem, CardList },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '灵活的应用场景',
						description: '适用于尾矿安全在线预警监测、边坡安全在线预警监测、露天矿采场、地质沉降安全监测系统等各个领域。',
						icon: require('./assets/qm-b1-0.png'),
						style: {
							height: window.flexible.px2rem(344) + 'rem',
						},
						contentWidth: '44%',
					},
					{
						title: '深厚的技术沉淀',
						description: '立足自主研发，不断革新GNSS技术，为新品、新技术的研发提供了切实的保障，最早于2012年建立尾矿库安全预警在线监测系统，连续运行时间超过8年。',
						icon: require('./assets/qm-b1-1.png'),
						style: {
							height: window.flexible.px2rem(344) + 'rem',
						},
						contentWidth: '50%',
					},
				],
				block2: {
					title: '专业的系统研发',
					description: '频无线数据传输技术，双向通讯，可以传输视频信号。监测软件采用专业的SQL数据库，并支持使用变化曲线、三维模型对监测点位移情况进行全面展示。报警功能和方式多样化，当各个方向的位移、速度、加速度超限时能通过短信、邮件、声光、网页弹出框进行报警。监测系统具有完善的防雷避雷措施，可以有效防止直接雷和感应雷对系统的影响。\n',
					icon: require('./assets/qm-b1-2.png'),
					contentWidth: '65%',
					style: {
						height: window.flexible.px2rem(344) + 'rem',
					},
					layout: 'x-revert',
				},
				block3: {
					title: '矿山监测安全云监控平台',
					en: 'Mine monitoring security cloud monitoring platform',
					sub: '矿山一体化',
					list: [
						{
							title: '物联网感知平台',
							sub: '物联网技术',
							icon: require('./assets/qm-b4-0.png'),
							iconHover: require('./assets/qm-b4-0-hover.png'),
							iconImg: require('./assets/qm-b4-0-d.png'),
							bg: require('./assets/qm-b4.png'),
							description: '物联网感知平台通常采用云计算、大数据、人工智能等技术，以实现对各种数据的集中管理和分析。具有监测设备数量、预警信息、监测点页面主览、多项目管理、监测数据快捷访问等功能。',
						},
						{
							title: '决策分析系统',
							sub: '数据智能化分析',
							icon: require('./assets/qm-b4-1.png'),
							iconHover: require('./assets/qm-b4-1-hover.png'),
							iconImg: require('./assets/qm-b4-1-d.png'),
							bg: require('./assets/qm-b4.png'),
							description: '矿山数据分析决策系统是一种基于大数据技术的智能决策支持系统，能够为矿山管理者提供全面、准确、及时的数据分析和决策支持，提高矿山生产效率和安全性。',
						},
						{
							title: '预警管理系统',
							sub: '预警阈值自主监控',
							icon: require('./assets/qm-b4-2.png'),
							iconHover: require('./assets/qm-b4-2-hover.png'),
							iconImg: require('./assets/qm-b4-2-d.png'),
							bg: require('./assets/qm-b4.png'),
							description: '预警阈值自主监控系统通常采用传感器、数据采集设备、数据分析软件等技术手段，实现对相关指标或参数的实时监测和预警。同时，该系统还需要具备可扩展性和可定制性，能够根据用户的需求进行灵活的调整和扩展。',
						}
					]
				}
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-quarry']['quarry-monitor']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
		banner () {
			return require('./assets/sq-qm-banner.png')
		},
	}
}
</script>

<style scoped lang="scss">
.quarry-monitor {
	.use-bg {
		background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b3-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 583px;
	}
}
</style>
