<template>
	<div class="smart-quarry">
		<top-nav :img-list="banner" is-parent>
			<div class="banner-item-title" style="text-align: center" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<div class="card-box flex space-between">
					<pin-card style="height: 121px;" v-for="t in block1" :key="t.title" :title="t.title" :icon="t.icon" :sub="t.sub"></pin-card>
				</div>
				<block-title style="margin-top: 80px;" :data="static.title1"></block-title>
				<img class="b1-img" style="margin-top: 40px;" src="./assets/smq-b1-3.png" alt="">
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<card-list style="margin-top: 40px;" :list="block2"></card-list>
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<card-list class="card-list-no-padding" style="margin-top: 40px;" :list="block3"></card-list>
			</div>
		</div>
		<my-footer></my-footer>
	</div>
</template>

<script>
import PinCard from "../../components/card-list/pin-card.vue";
import CardList from "../../components/card-list/index.vue";
import { mapGetters } from "vuex";

export default {
	name: "smart-quarry",
	components: { CardList, PinCard },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '矿山安全生产事故频发',
						sub: '面积广、设备多、人员复杂，难以管控',
						icon: require('./assets/sqm-b1-0.png')
					},
					{
						title: '视频监控系统效率低下',
						sub: '突发事件无法及时响应导致损失',
						icon: require('./assets/sqm-b1-1.png')
					},
					{
						title: '政策推进矿山智能化建设',
						sub: '智能化初级阶段，矿山发展空间广阔',
						icon: require('./assets/sqm-b1-2.png')
					},
				],
				block2: [
					{
						title: '算法多快好省',
						description: '成熟算法1200+，覆盖100+行业，平均准确率≥90%，全新算法定制仅需8-12周，全链条应用成本最优。',
						icon: require('./assets/smq-b2-0.png'),
						layout: 'y'
					},
					{
						title: '节省改造成本',
						description: '支持摄像头利旧使用，主流厂家如海康大华设备接入，RTSP,RTMP,GB28181等视频流接入。',
						icon: require('./assets/smq-b2-1.png'),
						layout: 'y'
					},
					{
						title: '系统自由对接',
						description: '标准API接口，灵活对接，拓展性强，打造最佳AI业务场景解决方案。',
						icon: require('./assets/smq-b2-2.png'),
						layout: 'y'
					},
					{
						title: '数据安全有保障',
						description: '支持本地化部署，保障算法效果的同时，防止数据外泄。',
						icon: require('./assets/smq-b2-3.png'),
						layout: 'y'
					},
					{
						title: '风险预判管控',
						description: '利用AI视频智能分析技术，实现安全风险预判管控，降低安全事故的发生率。',
						icon: require('./assets/smq-b2-4.png'),
						layout: 'y'
					},
					{
						title: '售后服务保障',
						description: '专属售后团队提供全天候售后服务；算法自训练与快速修复服务，可实现故障自动清除与算法迭代。',
						icon: require('./assets/smq-b2-5.png'),
						layout: 'y'
					},
				],
				block3: [
					{
						title: '多场景多算法组合分析',
						description: '基于人工智能计算机视觉技术，针对“人的不安全行为”、“物的不安全状态”“环境的不安全因素”三个方面，建设N个算法组合应用分析，实现助力矿山监控从“明眸到“智”，实现矿山全场景、全流程的智能化。',
						icon: require('./assets/smq-b3-0.png'),
					},
					{
						title: '实时监测增强反应速度',
						description: '支持第三方报警设备联动，通过算法的实时分析与现有的相关系统实现关联，并根据矿山的隐患管理措施制定相应的整改方案，完成闭环管理，减少矿山的安全风险。\n',
						icon: require('./assets/smq-b3-1.png'),
					},
					{
						title: '灵活对接可扩展性强',
						description: '能够根据今后业务不断深入发展的需要，兼容实现接口互通和后期功能拓展，标准接口可支持多种网络传输、多种物理接口对接，提供技术升级、设备更新的灵活性。\n',
						icon: require('./assets/smq-b3-2.png'),
					}
				]
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-quarry']['index']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
		banner() {
			return require('./assets/smart-quarry-banner.png')
		},
	},
}
</script>

<style scoped lang="scss">
.smart-quarry {
	.use-bg {
		background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b1-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 609px;
	}

}
</style>
