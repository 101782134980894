<template>
	<div class="digital-quarry">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: left;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list style="margin-top: 80px;" :list="block1"></card-list>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<img class="b4-img" style="margin-top: 40px;" src="./assets/we-b4.png" alt="">
			</div>
		</div>
		<div class="block-area pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<div class="b1-list" style="margin-top: 40px;">
					<div class="b-item" v-for="(t, i) in block2" :key="i">
						<img :src="t.icon" alt="">
						<div class="b-title">{{ t.title }}</div>
						<div class="b-hr" style="margin-top: 10px;"></div>
						<div class="b-sub" style="margin-top: 16px;">{{ t.sub }}</div>
						<div class="b-description" style="margin-top: 40px;">{{ t.description }}</div>
					</div>
				</div>
			</div>
		</div>
		<footer-resolution :data="static.title4"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import CardList from "../../components/card-list/index.vue";
import { mapGetters } from "vuex";

export default {
	name: "digital-quarry",
	components: { CardList },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '数字孪生技术',
						description: '实现矿山区域、设施、定位建筑构件的三维可视化管理。',
						icon: require('./assets/sq-b1-0.png'),
					},
					{
						title: 'GIS地理信息技术',
						description: '平面地图展示，提供周围的地形地貌景观，植物小品等周围的地理信息；同时提供相关设备的属性信息以及坐标位置信息查询，服务推送,位置服务标定。',
						icon: require('./assets/sq-b1-1.png'),
					},
					{
						title: '激光扫描技术',
						description: '利用激光扫描技术快速的构建三维模型，包括建筑物周围的地形地貌，项目现场以及重要的设施设备。',
						icon: require('./assets/sq-b1-2.png'),
					},
					{
						title: 'AI人工智能技术',
						description: '用于识别场景的内部图像，如人脸识别、车辆车号牌的抓取、烟火识别、仪表读取等。',
						icon: require('./assets/sq-b1-3.png'),
					},
					{
						title: '北斗定位技术',
						description: '基于卫星导航定位系统技术，利用北斗地基增强站以及星基增强系统和自主研发的定位算法，通过互联网技术进行，车辆位置数据运算。',
						icon: require('./assets/sq-b1-4.png'),
					},
				],
				block2: [
					{
						title: '三维可视化',
						sub: '矿山地形监测',
						description: '矿山地形及各类设备三维可视\n\n矿山地质勘探数据、生产勘探数据以及露天矿炮孔信息地图显示。',
						icon: require('./assets/we-b5-0.png')
					},
					{
						title: '车辆调度',
						sub: '车辆定位终端',
						description: '通过北斗高精度定位硬件终端、高精度定位服务以及基于二维、三维高精度地图的可视化监管平台，满足封闭场景的实时定位、历史轨迹、电子围栏、监控报警、统计报表等功能诉求，实现车辆管理的全程化、透明化、精细化。',
						icon: require('./assets/we-b5-1.png')
					},
					{
						title: '人员定位',
						sub: '人员作业位置监测',
						description: '基于亚米级/厘米级的定位服务，多样化的北斗高精度作业人员位置监测终端及基于二维、三维高精度地图的可视化监管平台，满足实时位置、历史轨迹、电子围栏、监控报警、统计报表等功能，实现作业人员位置监测及管理的智能化调度和精细化运营。',
						icon: require('./assets/we-b5-2.png')
					},
					{
						title: 'AI视频',
						sub: '实时视频监控',
						description: '视频对生产区域进行全天候实时监控\n\n集成AI智能分析功能，对安全帽佩戴、火灾隐患、漏料进行识别分析',
						icon: require('./assets/we-b5-3.png')
					},
					{
						title: '无人机巡检',
						sub: '智能巡检、进度管理',
						description: '系统集成商提供无人机自主飞行解决方案。结合高精度地图的管理平台，无人机可按设定轨迹，精准飞行，自主完成为农业植保、国土测绘、电力巡检、公路巡检、物流运输等多种场景的作业要求。',
						icon: require('./assets/we-b5-4.png')
					},
					{
						title: '边坡监测',
						sub: '边坡的自动化安全监测',
						description: '通过在边坡布设一体化智能监测站，对边坡的地表变形、深部变形地表裂缝、地下水位、降雨量等进行实时监测，在智能网关进行边缘计算后，将数据上传云平台，进行远程在线实时边坡监测、分析和预警。',
						icon: require('./assets/we-b5-5.png')
					},
				]
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-quarry']['digital-quarry']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		banner() {
			return require('./assets/smart-quarry-banner.png')
		},
	},
}
</script>

<style scoped lang="scss">
.digital-quarry {
	.use-bg {
		background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b4-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 830px;
	}
	.b1-list {
		display: grid;
		grid-template-columns: repeat(3, 356px);
		grid-template-rows: 525px 525px;
		row-gap: 80px;
		justify-content: space-between;
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: -2;
		}
		.b-item {
			padding: 40px;
			box-sizing: border-box;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				transition: background-color .2s;
				background: transparent;
			}
			&:hover {
				.b-description {
					color: #FFFFFF;
				}
				&:before {
					background: rgba(78, 153, 253, 0.75);
				}
			}
		}
		.b-title {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
		}
		.b-hr {
			width: 73px;
			height: 8px;
			background: #4E99FD;
			border-radius: 7px 7px 7px 7px;
		}
		.b-sub {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
		}
		.b-description {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			line-height: 32px;
			color: transparent;
			transition: color .2s;
		}
	}
}
</style>
