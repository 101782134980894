<template>
  <div>
    <top-nav> </top-nav>
    <div class="empty">
      <img class="img" src="@/asset/index/ic_empty.png" alt="" />
      <div class="txt">您的网页走丢了！</div>
      <div class="button" @click="refresh">点击刷新</div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    refresh() {
      this.$router.replace("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.empty {
  background: #edf0f5;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  position: fixed;
  text-align: center;
  .img {
    margin-top: 100px;
    width: 1100px;
  }
  .txt {
    font-size: 24px;
    color: #666666;
    position: relative;
    top: -64px;
  }
  .button {
    width: 200px;
    height: 60px;
    background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
    box-shadow: 3px 3px 8px 1px #e8e8e8;
    border-radius: 2px 2px 2px 2px;
    line-height: 60px;
    text-align: center;
    font-size: 24px;
    color: white;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>