<template>
	<div class="disaster-monitor">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: left;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<div class="tt-list">
					<div class="tt-item" v-for="(t, i) in block1" :key="i">
						<img :src="t.icon" alt="">
						<div class="tt">{{ t.title }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list style="margin-top: 80px;" :list="block2"></card-list>
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<img class="b3-img" style="margin-top: 40px;" src="./assets/dm-b3.png" alt="">
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<card-list style="margin-top: 80px;" :list="block3"></card-list>
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title4"></block-title>
				<div class="tt-list" style="margin-top: 80px;">
					<div class="tt-item">
						<img src="./assets/dm-b5-0.png" alt="">
						<div class="tt2">{{ static.p1[0] }}</div>
					</div>
					<div class="tt-item">
						<img src="./assets/dm-b5-1.png" alt="">
						<div class="tt2">{{ static.p1[1] }}</div>
					</div>
					<div class="tt-item">
						<img src="./assets/dm-b5-2.png" alt="">
						<div class="tt2">{{ static.p1[2] }}</div>
					</div>
				</div>
			</div>
		</div>
		<introduce-carousel  :list="block4.list" :title="block4.title" :en="block4.en" :sub="block4.sub"></introduce-carousel>
		<footer-resolution style="background-image: none;" :data="static.title5"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CardList from "../../components/card-list/index.vue";
import IntroduceCarousel from "../../components/introduce-list/introduce-carousel.vue";

export default {
	name: "disaster-monitor",
	components: { IntroduceCarousel, CardList },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '数据稳定',
						icon: require('./assets/dm-b1-0.png')
					},
					{
						title: '实时监测',
						icon: require('./assets/dm-b1-1.png')
					},
					{
						title: '标准规则',
						icon: require('./assets/dm-b1-2.png')
					},
					{
						title: '决策分析',
						icon: require('./assets/dm-b1-3.png')
					},
					{
						title: '智能预警',
						icon: require('./assets/dm-b1-4.png')
					},
				],
				block2: [
					{
						title: '智能化',
						description: '多数系统只简单将雨、水情数据与标定值进行对比。没有建立流域多维度智能研判、分析模型。容易造成误报漏报，给基层防汛工作和群众带来很多困扰和风险。',
						icon: require('./assets/dm-b2-0.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(243)
						}
					},
					{
						title: '可靠性',
						description: '由于缺乏针对性设计，监测系统对抗灾毁性、断电、断网等环境条件考虑和设计不充分。在山洪暴发时可能难以发挥作用',
						icon: require('./assets/dm-b2-1.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(243)
						}
					},
					{
						title: '实时性',
						description: '难以针对县域内众多山洪流域的突发性小范围强降雨进行及时有效监测和预警。',
						icon: require('./assets/dm-b2-2.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(243)
						}
					},
					{
						title: '指挥调度',
						description: '系统普遍存在过度依赖人工判断和通知，信息发布渠道单一，覆盖范围和效果不理想，信息反馈不及时，缺乏任务分配和跟踪手段等诸多问题。',
						icon: require('./assets/dm-b2-3.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(243)
						}
					},
				],
				block3: [
					{
						title: '为科技决策提供依据',
						description: '系统可快速采集、传输、计算、分析、存储各监测点的监测数据，包括雨量、泥水位、地声、次声、孔隙水压力、土体沉降、地表裂缝、深部位移、地下水、土压力、表面位移、土壤含水量、图像视频、电源电压和环境温度等，并对数据进行纠错处理，减少数据误码率、提高数据完整率。',
						icon: require('./assets/dm-b4-0.png')
					},
					{
						title: '有效保障数据传输',
						description: '系统支持GPRS/3G/4G/北斗等通信方式，传感器节点、终端节点以及中心节点之间还可通过LoRa无线传感网络进行相互通信。',
						icon: require('./assets/dm-b4-1.png')
					},
					{
						title: '异常自动报警',
						description: '7*24小时全天侯自动监测地质各维度参数，自动进行数据分析判断，当检测值超过预警值时，自动声光报警、短信报警，实现无人值守日夜守护地区安全，极大降低管理人员工作强度，提升管理效率。\n',
						icon: require('./assets/dm-b4-2.png')
					}
				],
				block4: {
					title: '应用展示',
					en: 'Application Display',
					sub: '智能一体化',
					list: [
						{
							title: '山洪监测预警',
							sub: '山洪监测预警系统',
							icon: require('./assets/dm-b6-0.png'),
							iconHover: require('./assets/dm-b6-0-hover.png'),
							iconImg: require('./assets/dm-b6-d.png'),
							bg: require('./assets/dm-b6-bg.png'),
							description: '山洪监测预警系统是利用大数据、物联网、虚拟仿真等技术，按照“可视、可知、可控”的总体要求，通过新建、升级、改造雨、水情站点、地灾监测站点、山火监测站点、预警广播、梯级调度中心等数字孪生基础设施，实现智慧监测、指挥预报、智慧调度、智慧管理相关场景深度数字化应用场景打造。',
						},
						{
							title: '智能山林防火系统',
							sub: '智能山林防火系统',
							icon: require('./assets/dm-b6-1.png'),
							iconHover: require('./assets/dm-b6-1-hover.png'),
							iconImg: require('./assets/dm-b6-d1.webp'),
							bg: require('./assets/dm-b6-bg.png'),
							description: '智慧山林防火系统通过AI智能算法、云计算、大数据、GIS地图等信息技术，实现山林防火智能化监测告警和管理。通过实时监测和告警功能，及时掌控林区内早期火情动态，大幅提高预防监测能力，在山林早期火情监测、告警以及火灾扑救过程中发挥作用。',
						},
						{
							title: '地质灾害监测系统',
							sub: '地质灾害监测预警系统',
							icon: require('./assets/dm-b6-2.png'),
							iconHover: require('./assets/dm-b6-2-hover.png'),
							iconImg: require('./assets/dm-b6-d2.webp'),
							bg: require('./assets/dm-b6-bg.png'),
							description: '地质灾害监测包含了外界环境因素(降雨、温度、地震加速度、孔隙水压力等)、变形( 地表位移深部位移等)以及压力应变(土压力、应变等)等众多内容。依据要求，地表位移监测以GNSS地表绝对位移和裂缝相对位移为主;选择降雨量监测、土体含水量(含水率)监测为次监测内容。结合多种监测采集设备，采用物联网、大数据、人工智能等技术实现实时动态安全监测，形成多源一体自动化监测解决方案。',
						}
					]
				}
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-city']['disaster-monitor']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
		block4() {
			return window.deepMerge(this.defines.block4, this.static.block4)
		},
		banner () {
			return require('./assets/sc-ll-banner.png')
		},
	}
}
</script>

<style scoped lang="scss">
.disaster-monitor {
	.use-bg {
		background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b3-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 581px;
	}
	.tt-list {
		display: flex;
		justify-content: space-between;
	}
	.tt-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		.tt {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 500;
			font-size: 24px;
			color: #333333;
		}
		.tt2 {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 24px;
			color: #333333;
			margin-top: 24px;
		}
	}
}
</style>
