<template>
	<div class="custom-select">
		<div class="selected-option" @click="toggleOptions">
			<span :class="{isPlaceHolder: selectedLabel === placeholder}">{{ selectedLabel }}</span>
			<i class="arrow"></i>
		</div>
		<ul class="options" v-show="showOptions">
			<li v-for="(option, i) in options" :key="option.value" @click="selectOption(option, i)">
				{{ option.label }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'n-select',
	data() {
		return {
			selectedOption: null,
			showOptions: false,
		};
	},
	props: {
		options: {
			type: Array,
			default: () => []
		},
		placeholder: {
			type: String,
			default: ''
		}
	},
	computed: {
		selectedLabel() {
			return this.selectedOption ? this.selectedOption.label : this.placeholder;
		},
	},
	methods: {
		toggleOptions() {
			this.showOptions = !this.showOptions;
		},
		selectOption(option, i) {
			this.$emit('change', i)
			this.selectedOption = option;
			this.showOptions = false;
		},
	},
};
</script>

<style>
.custom-select {
	position: relative;
	width: 224px;
}

.selected-option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 12px;
	border: 1px solid rgba(221, 221, 221, 1);
	border-radius: 4px;
	cursor: pointer;
	transition: all .2s;
	height: 60px;
	font-family: Source Han Sans CN, Source Han Sans CN;
	font-weight: 400;
	font-size: 14px;
	box-sizing: border-box;
}
.isPlaceHolder {
	color: rgba(153, 153, 153, 1)
}

.selected-option:hover {
	background-color: #f5f5f5;
}

.arrow {
	border: solid #999999;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
}

.options {
	position: absolute;
	top: calc(100% + 5px);
	left: 0;
	width: 100%;
	max-height: 200px;
	overflow-y: auto;
	background-color: #ffffff;
	border: 1px solid rgba(221, 221, 221, 1);
	border-radius: 4px;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.options li {
	padding: 10px;
	cursor: pointer;
	transition: all .2s;
}

.options li:hover {
	background-color: #f2f2f2;
}
</style>
