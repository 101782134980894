import { render, staticRenderFns } from "./get-plan.vue?vue&type=template&id=a78ff1d4&scoped=true"
import script from "./get-plan.vue?vue&type=script&lang=js"
export * from "./get-plan.vue?vue&type=script&lang=js"
import style0 from "./get-plan.vue?vue&type=style&index=0&id=a78ff1d4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_zh5i3lkul6ts2ya5x5g6w3bfg4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a78ff1d4",
  null
  
)

export default component.exports