<template>
	<div class="smart-city">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: left;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list style="margin-top: 80px;" :list="block1"></card-list>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content advantage">
				<block-title :data="static.title2"></block-title>
				<div class="content content-limit">
					<div class="left">
						<img class="icon" src="@/asset/index/ic_index_29.png" alt="" />
						<div class="title">{{ static.p1[0].title }}</div>
						<div class="en-title">{{ static.p1[0].en.toUpperCase() }}</div>
						<div class="brief">{{ static.p1[0].description }}</div>
					</div>
					<div class="right">
						<div class="item">
							<img class="icon" src="@/asset/index/ic_index_28.png" alt="" />
							<div class="title">{{ static.p1[1].title }}</div>
							<div class="en-title">{{ static.p1[1].en.toUpperCase() }}</div>
							<div class="brief">{{ static.p1[1].description }}</div>
						</div>
						<div class="item">
							<img class="icon" src="@/asset/index/ic_index_27.png" alt="" />
							<div class="title">{{ static.p1[2].title }}</div>
							<div class="en-title">{{ static.p1[2].en.toUpperCase() }}</div>
							<div class="brief">{{ static.p1[2].description }}</div>
						</div>
						<div class="item">
							<img class="icon" src="@/asset/index/ic_index_47.png" alt="" />
							<div class="title">{{ static.p1[3].title }}</div>
							<div class="en-title">{{ static.p1[3].en.toUpperCase() }}</div>
							<div class="brief">{{ static.p1[3].description }}</div>
						</div>
						<div class="item">
							<img class="icon" src="@/asset/index/ic_index_26.png" alt="" />
							<div class="title">{{ static.p1[4].title }}</div>
							<div class="en-title">{{ static.p1[4].en.toUpperCase() }}</div>
							<div class="brief">{{ static.p1[4].description }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<img class="b3-img" style="margin-top: 40px;" src="./assets/ll-b3.png" alt="">
			</div>
		</div>
		<div class="drive" :style="{background: `url(${require('./assets/ll-b4-bg.png')}) no-repeat center/cover`}">
			<div class="en-title">{{ static.p2.en.toUpperCase() }}</div>
			<div class="ch-title">{{ static.p2.title }}</div>
			<div class="ch-brief">{{ static.p2.description }}</div>
			<div class="content content-limit">
				<div class="left">
					<div class="item" @click="driveCurrentIndex = 0">
						<img
							class="item-icon"
							src="./assets/ll-b4-0-hover.png"
							alt=""
							v-if="driveCurrentIndex === 0"
						/>
						<img
							class="item-icon"
							src="./assets/ll-b4-0.png"
							alt=""
							v-else
						/>
						<div class="item-txt">{{ static.p2.list[0].left }}</div>
					</div>
					<div class="item" @click="driveCurrentIndex = 1">
						<img
							class="item-icon"
							src="./assets/ll-b4-1-hover.png"
							alt=""
							v-if="driveCurrentIndex === 1"
						/>
						<img
							class="item-icon"
							src="./assets/ll-b4-1.png"
							alt=""
							v-else
						/>
						<div class="item-txt">{{ static.p2.list[1].left }}</div>
					</div>
					<div class="item" @click="driveCurrentIndex = 2">
						<img
							class="item-icon"
							src="./assets/ll-b4-2-hover.png"
							alt=""
							v-if="driveCurrentIndex === 2"
						/>
						<img
							class="item-icon"
							src="./assets/ll-b4-2.png"
							alt=""
							v-else
						/>
						<div class="item-txt">{{ static.p2.list[2].left }}</div>
					</div>
					<div class="item" @click="driveCurrentIndex = 3">
						<img
							class="item-icon"
							src="./assets/ll-b4-3-hover.png"
							alt=""
							v-if="driveCurrentIndex === 3"
						/>
						<img
							class="item-icon"
							src="./assets/ll-b4-3.png"
							alt=""
							v-else
						/>
						<div class="item-txt">{{ static.p2.list[3].left }}</div>
					</div>
					<div class="item" @click="driveCurrentIndex = 4">
						<img
							class="item-icon"
							src="./assets/ll-b4-4-hover.png"
							alt=""
							v-if="driveCurrentIndex === 4"
						/>
						<img
							class="item-icon"
							src="./assets/ll-b4-4.png"
							alt=""
							v-else
						/>
						<div class="item-txt">{{ static.p2.list[4].left }}</div>
					</div>
					<div
						class="item-strip"
						:style="{ top: 20 * driveCurrentIndex + '%' }"
					></div>
				</div>
				<div class="right">
					<div class="item" v-if="driveCurrentIndex === 0">
						<div class="title">{{ static.p2.list[0].title }}</div>
						<div class="brief">{{ static.p2.list[0].brief }}</div>
						<div class="more-button" @click="$bus.$emit('online-chat')">
							在线咨询
							<img
								src="@/asset/index/ic_index_48.png"
								class="more-icon"
								alt=""
							/>
						</div>
					</div>
					<div class="item" v-if="driveCurrentIndex === 1">
						<div class="title">{{ static.p2.list[1].title }}</div>
						<div class="brief">{{ static.p2.list[1].brief }}</div>
						<div class="more-button" @click="$bus.$emit('online-chat')">
							在线咨询
							<img
								src="@/asset/index/ic_index_48.png"
								class="more-icon"
								alt=""
							/>
						</div>
					</div>
					<div class="item" v-if="driveCurrentIndex === 2">
						<div class="title">{{ static.p2.list[2].title }}</div>
						<div class="brief">{{ static.p2.list[2].brief }}</div>
						<div class="more-button" @click="$bus.$emit('online-chat')">
							在线咨询
							<img
								src="@/asset/index/ic_index_48.png"
								class="more-icon"
								alt=""
							/>
						</div>
					</div>
					<div class="item" v-if="driveCurrentIndex === 3">
						<div class="title">{{ static.p2.list[3].title }}</div>
						<div class="brief">{{ static.p2.list[3].brief }}</div>
						<div class="more-button" @click="$bus.$emit('online-chat')">
							在线咨询
							<img
								src="@/asset/index/ic_index_48.png"
								class="more-icon"
								alt=""
							/>
						</div>
					</div>
					<div class="item" v-if="driveCurrentIndex === 4">
						<div class="title">{{ static.p2.list[4].title }}</div>
						<div class="brief">{{ static.p2.list[4].brief }}</div>
						<div class="more-button" @click="$bus.$emit('online-chat')">
							在线咨询
							<img
								src="@/asset/index/ic_index_48.png"
								class="more-icon"
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title4"></block-title>
				<case-list :list="block2"></case-list>
			</div>
		</div>
		<div class="block-area pb-40px">
			<div class="block-content">
				<block-title :data="static.title5"></block-title>
				<div class="zig-card" style="margin-top: 40px;">
					<img src="./assets/ll-b6.png" alt="">
					<div class="word-context" v-html="static.p3"></div>
				</div>
				<div class="card-box flex space-between" style="margin-top: 40px;">
					<pin-card style="height: 121px;" v-for="t in block3" :key="t.title" :title="t.title" :icon="t.icon" :sub="t.sub"></pin-card>
				</div>
			</div>
		</div>
		<footer-resolution :data="static.title6"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import CardList from "../../components/card-list/index.vue";
import { mapGetters } from "vuex";
import PinCard from "../../components/card-list/pin-card.vue";

export default {
	name: "life-line",
	components: { PinCard, CardList },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '城市要素数据未实现数字化',
						description: '各类城市要素数据仍是纸质材料、光盘或非结构化数据，难以利用。',
						icon: require('./assets/ll-b1-0.png'),
						style: {
							height: window.flexible.px2rem(164) + 'rem'
						},
						layout: 'x'
					},
					{
						title: '缺乏有效的智能监测手段',
						description: '缺乏高效、实时、智能化的手段对各类城市桥梁、管网、房屋、城市部件等要素进行监测',
						icon: require('./assets/ll-b1-1.png'),
						style: {
							height: window.flexible.px2rem(164) + 'rem'
						},
						layout: 'x'
					},
					{
						title: '未实现及时预警',
						description: '当灾害事件出现端倪时，未能及时通知相关管理人员。',
						icon: require('./assets/ll-b1-2.png'),
						style: {
							height: window.flexible.px2rem(164) + 'rem'
						},
						layout: 'x'
					},
					{
						title: '未能快速、高效地灾后评估',
						description: '灾害发生后未能及时进行量化评估，不利于管理者及时做出有效决策和遏制次生灾害',
						icon: require('./assets/ll-b1-3.png'),
						style: {
							height: window.flexible.px2rem(164) + 'rem'
						},
						layout: 'x'
					},
				],
				block2: [
					{
						name: '城市管网',
						img: require('./assets/ll-b5-0.png')
					},
					{
						name: '智慧桥梁',
						img: require('./assets/ll-b5-1.png')
					},
					{
						name: '智慧水域',
						img: require('./assets/ll-b5-2.png')
					},
					{
						name: '智慧管廊',
						img: require('./assets/ll-b5-3.png')
					},
					{
						name: '智慧水利',
						img: require('./assets/ll-b5-4.png')
					},
					{
						name: '智慧边坡',
						img: require('./assets/ll-b5-5.png')
					}
				],
				block3: [
					{
						sub: '实现——全方位全天候的实时感知监测',
						icon: require('./assets/ll-b6-0.png')
					},
					{
						sub: '实现——提升城市安全风险预警防空能力',
						icon: require('./assets/ll-b6-0.png')
					},
					{
						sub: '实现——由“以治为主”向“以防为主”的转变',
						icon: require('./assets/ll-b6-0.png')
					},
				],
			},
			driveCurrentIndex: 0,
		}
	},
	computed: {
		...mapGetters(['globalData']),
		static() {
			return this.globalData['smart-city']['life-line']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
		banner () {
			return require('./assets/sc-ll-banner.png')
		},
	}
}
</script>

<style scoped lang="scss">
.smart-city {
	.use-bg {
		background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b3-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 856px;
	}
	.advantage {
		.content {
			display: flex;
			justify-content: center;
			gap: 24px;
			margin-top: 60px;

			.left {
				color: white;
				background: url(@/asset/index/ic_index_21.png) no-repeat center/cover;
				padding: 70px 40px;
				width: 427px;
				height: 687px;
				box-sizing: border-box;
				flex-shrink: 0;
				.icon {
					width: 60px;
					margin-bottom: 40px;
				}
				.title {
					font-size: 28px;
					margin-bottom: 8px;
				}
				.en-title {
					font-size: 12px;
					margin-bottom: 40px;
				}
				.brief {
					font-size: 16px;
					line-height: 2;
				}
			}
			.right {
				display: flex;
				flex-wrap: wrap;
				width: 690px;
				gap: 24px;
				.item {
					padding: 40px;
					width: 328px;
					height: 332px;
					box-sizing: border-box;
					border: 1px solid #4e99fd;
					border-radius: 4px;
					.icon {
						height: 60px;
						margin-bottom: 30px;
					}
					.title {
						font-size: 28px;
						color: #333333;
						margin-bottom: 8px;
					}
					.en-title {
						font-size: 12px;
						color: #999999;
						margin-bottom: 20px;
					}
					.brief {
						font-size: 16px;
						color: #666666;
						line-height: 2;
					}
				}
			}
		}
	}
	.more-button {
		width: 217px;
		height: 61px;
		background: #4e99fd;
		border-radius: 2px 2px 2px 2px;
		font-size: 24px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		cursor: pointer;
		.more-icon {
			width: 30px;
		}
	}
	.drive {
		line-height: 1;
		padding: 80px 0;
		transition: 0.5s all;
		.en-title {
			font-size: 36px;
			color: rgba(255, 255, 255, 0.4);
			margin-bottom: 10px;
			text-align: center;
		}
		.ch-title {
			font-size: 36px;
			color: white;
			margin-bottom: 34px;
			text-align: center;
			font-family: YouSheBiaoTiHei;
		}
		.ch-brief {
			font-size: 20px;
			color: rgba(255, 255, 255, 0.6);
			text-align: center;
			margin-bottom: 80px;
		}
		.content {
			display: flex;
			height: 420px;
			.left {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				border-right: 2px solid rgba($color: white, $alpha: 0.6);
				flex-shrink: 0;
				.item {
					display: flex;
					align-items: center;
					gap: 8px;
					padding-right: 40px;
					cursor: pointer;
					.item-icon {
						width: 20px;
					}
					.item-txt {
						font-size: 20px;
						font-weight: bold;
						color: white;
					}
				}
				.item-strip {
					position: absolute;
					right: 0;
					top: 0;
					transform: translateX(50%);
					width: 4px;
					height: 20%;
					transition: all 0.5s;
					margin-right: -1px;
					background: #4e99fd;
				}
			}

			.right {
				padding-left: 80px;
				.item {
					.title {
						font-size: 36px;
						color: white;
					}
					.brief {
						font-size: 20px;
						line-height: 2;
						margin: 40px 0;
						color: rgba($color: white, $alpha: 0.6);
					}
				}
			}
		}
	}

	.zig-card {
		display: flex;
		align-items: center;
		.word-context {
			width: 576px;
			height: 337px;
			box-sizing: border-box;
			background: #FFFFFF;
			border-radius: 1px 1px 1px 1px;
			padding: 60px;
			display: flex;
			align-items: center;
			transform: translateX(-30px);

			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 14px;
			color: #666666;
			line-height: 28px;

			filter: drop-shadow(-5px 10px 0 rgba(0, 0, 0, 0.25));
		}
	}
}
</style>
