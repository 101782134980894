<template>
	<div class="display-list">
		<div class="block-content">
			<div class="dl-item" v-for="(t, i) in list" :key="i">
				<img class="dl-board" :src="t.icon" alt="">
				<div class="dl-content">
					<div class="dl-title">{{ t.title }}</div>
					<div class="dl-hr"></div>
					<div class="dl-description">{{ t.description }}</div>
					<div class="dl-button" v-if="t.btn" @click="t.btn.onclick">
						{{ t.btn.label }}
						<img style="margin-left: 12px;" src="@/asset/common/images/more-arrow.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "display-list",
	props: {
		list: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style scoped lang="scss">
.display-list {
	.block-content {
		display: flex;
		flex-direction: column;
		gap: 80px;
	}
	.dl-item {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 80px;
	}
	.dl-board {
		object-fit: cover;
		width: 100%;
	}

	.dl-content {

	}
	.dl-title {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: bold;
		font-size: 24px;
		color: #4E99FD;
	}
	.dl-hr {
		width: 80px;
		height: 4px;
		background: #4E99FD;
		margin-top: 16px;
	}
	.dl-description {
		margin-top: 40px;
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 18px;
		color: #666666;
		line-height: 36px;
		white-space: pre-wrap;
	}
	.dl-button {
		margin-top: 40px;
		width: 217px;
		height: 61px;
		background: #4E99FD;
		border-radius: 2px 2px 2px 2px;
		cursor: pointer;

		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 24px;
		color: #FFFDFD;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
