<template>
  <div class="container">
    <top-nav :imgList="banner">
      <div class="banner-item-title">
        {{ globalData.smartMonitorResolution.title }}
      </div>
      <div class="banner-item-brief">
        {{ globalData.smartMonitorResolution.brief }}
      </div>
    </top-nav>
    <div class="structure">
      <div
        class="card-box flex space-between content-limit"
        style="margin-bottom: 80px"
      >
        <pin-card
          v-for="t in block1"
          :key="t.title"
          :title="t.title"
          :icon="t.icon"
          :sub="t.sub"
        ></pin-card>
      </div>
      <block-title
        :title="globalData.smartMonitorResolution.structure.top.title"
        :en="globalData.smartMonitorResolution.structure.top.en"
        :sub="globalData.smartMonitorResolution.structure.top.sub"
      ></block-title>
      <img
        class="img content-limit"
        src="@/asset/smart-traffic/ic_traffic_73.png"
        alt=""
      />
    </div>
    <div class="feature">
      <block-title
        :title="globalData.smartMonitorResolution.feature.top.title"
        :en="globalData.smartMonitorResolution.feature.top.en"
        :sub="globalData.smartMonitorResolution.feature.top.sub"
      ></block-title>
      <div class="content-limit">
        <card-list :list="block4" style="margin-top: 80px"></card-list>
      </div>
    </div>
    <div class="footer-resolution">
      <div class="block-content layout">
        <div class="left">
          <div class="f-title">
            {{ globalData.smartMonitorResolution.footer.title }}
          </div>
          <div class="f-en">
            {{ globalData.smartMonitorResolution.footer.en.toUpperCase() }}
          </div>
          <div class="f-sub">
            {{ globalData.smartMonitorResolution.footer.sub }}
          </div>
        </div>
        <div class="right">
          <div class="ask-btn">立即申请</div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PinCard from "../../components/card-list/pin-card.vue";
import CardList from "../../components/card-list/index.vue";

export default {
  computed: {
    ...mapGetters(["globalData"]),
    block1() {
      return window.deepMerge(
        [
          {
            title: "环境监测",
            sub: "结构附件或结构内部",
            icon: require("@/asset/smart-traffic/ic_traffic_02.png"),
          },
          {
            title: "变形监测",
            sub: "边坡关键位置",
            icon: require("@/asset/smart-traffic/ic_traffic_03.png"),
          },
          {
            title: "应力及受力监测",
            sub: "挡土重要位置",
            icon: require("@/asset/smart-traffic/ic_traffic_04.png"),
          },
        ],
        this.globalData.smartMonitorResolution.structure.block1
      );
    },
    block4() {
      return window.deepMerge(
        [
          {
            title: "精度达毫米级",
            description:
              "应用北斗卫星导航定位系统，拥有行业最高认证精度，全系统多频点抗干扰能力强，同时公司具备完整的数据后处理方案，实现地质灾害点表面三维形变高精度监测。",
            icon: require("@/asset/smart-traffic/ic_traffic_71.png"),
          },
          {
            title: "支持多模通信",
            description:
              "支持GPRS/北斗短报文/4G/LoRa/NB-lot等多种通信方式，可做备份传输，信息传输实时性强。",
            icon: require("@/asset/smart-traffic/ic_traffic_70.png"),
          },
          {
            title: "超低功耗设计",
            description:
              "一体化集成设计，低功耗支持多年免维护运行，设备无故障时间达到3年以上，性能稳定，安装简便，监测种类齐全",
            icon: require("@/asset/smart-traffic/ic_traffic_69.png"),
          },
          {
            title: "远程设备管理",
            description:
              "真正实现设备远程互联，可借助平台实现设备的远程管理以及系统升级同时也支持手机客户端登陆系统，随时随地方便快捷查看数据和报警信息",
            icon: require("@/asset/smart-traffic/ic_traffic_68.png"),
          },
          {
            title: "系统可靠性高",
            description:
              "针对不同灾害形态，有效进行传感器选择搭配，同时积极响应国家政策，实现普适型监测设备推广，既做到了成本控制，同时也保证系统长时间工作的稳定性和可靠性。",
            icon: require("@/asset/smart-traffic/ic_traffic_67.png"),
          },
          {
            title: "自主平台服务",
            description:
              "利用云服务实时进行数据的分发、处理、均衡、管理与分析，并利用web进行数据的交互，针对每一个监测点情况进行专业模型分析，根据数据融合结果起到预警与调度作用",
            icon: require("@/asset/smart-traffic/ic_traffic_66.png"),
          },
        ],
        this.globalData.smartMonitorResolution.feature.block4
      );
    },
  },
  components: {
    PinCard,
    CardList,
  },
  data() {
    return {
      banner: require("@/asset/smart-traffic/ic_traffic_01.png"),
    };
  },
};
</script>
<style lang="scss" scoped>
.structure {
  padding: 100px 0 80px;
  .img {
    display: block;
    margin-top: 40px;
  }
}
.feature {
  background: url(@/asset/smart-traffic/ic_traffic_65.png) no-repeat
    center/cover;
  padding: 80px 0 40px;
}
.footer-resolution {
  height: 304px;
  background-size: cover;

  .layout {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
  }
  .f-title {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 36px;
    color: #333333;
    margin-top: 60px;
  }
  .f-en {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 24px;
    color: #999999;
    margin-top: 10px;
  }
  .f-sub {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    margin-top: 40px;
  }
  .ask-btn {
    width: 200px;
    height: 60px;
    background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
    box-shadow: 3px 3px 8px 1px #e8e8e8;
    border-radius: 2px 2px 2px 2px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
}
</style>
