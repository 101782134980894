<template>
  <div
    class="strip-topic"
    :class="{
      'strip-left': position === 'left',
      'strip-right': position === 'right',
    }"
  >
    <div class="strip-title">{{ title }}</div>
    <pre v-if="brief" class="strip-brief">{{ brief }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    brief: {
      type: String,
    },
    position: {
      type: String,
      default: "center",
    },
  },
};
</script>

<style scoped lang="scss">
.strip-topic {
  text-align: center;
  .strip-title {
    color: #333333;
    font-size: 36px;
    font-weight: 500;
    position: relative;
    // &::after {
    //   content: "";
    //   background: #0f52fb;
    //   height: 15px;
    //   width: 100%;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    // }
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
  .strip-subtitle {
    color: #999999;
    font-size: 14px;
    margin-top: 28px;
  }
  .strip-brief {
    color: #666666;
    font-size: 24px;
    display: inline-block;
    line-height: 2;
    font-family: "Source Han Sans CN-Medium", serif;
    @media screen and (max-width: 767px) {
      white-space: normal;
      word-wrap: break-word;
      font-size: 16px;
    }
  }
}
.strip-left {
  text-align: left;
}
.strip-right {
  text-align: right;
}
@media screen and (max-width: 767px) {
  .strip-topic {
    text-align: center;
  }
}
</style>
