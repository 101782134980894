const ls = window.localStorage;
const ss = window.sessionStorage;

console.log('defines');

const utils = {
	// 本地长期存储
	lStorage: {
		set(key = '', value = '') {
			ls.setItem(key, typeof value == 'string' ? value : JSON.stringify(value));
		},
		get(key = '') {
			let value = ls.getItem(key);

			try {
				value = JSON.parse(value);
				if (typeof value == 'number') {
					return value + '';
				} else {
					return value;
				}
			} catch (e) {
				return value;
			}
		},
		clear() {
			ls.clear();
		},
		key(num = 0) {
			return ls.key(num);
		},
		del(key = '') {
			ls.removeItem(key);
		}
	},
	// 本地会话存储
	sStorage: {
		set(key = '', value = '') {
			ss.setItem(key, typeof value == 'string' ? value : JSON.stringify(value));
		},
		get(key = '') {
			let value = ss.getItem(key);

			try {
				value = JSON.parse(value);
				if (typeof value == 'number') {
					return value + '';
				} else {
					return value;
				}
			} catch (e) {
				return value;
			}
		},
		clear() {
			ss.clear();
		},
		key(num = 0) {
			return ss.key(num);
		},
		del(key = '') {
			ss.removeItem(key);
		}
	},
	// 后台时间转换
	dateParse(date) {
		var datereg = /\d{4}(-|\.|\s)\d{2}(-|\.|\s)\d{2}/;
		var timereg = /\d{2}:\d{2}:\d{2}/;
		var dates = datereg.exec(date)[0];
		var times = timereg.exec(date)[0];
		var millisecond = new Date(dates.replace(/-|\.|\s/g, '/') + " " + times).getTime(); //ios时间字符串只能识别'/',如'new Date(1900/01/01)'
		var dateObj = new Date(dates.replace(/-|\.|\s/g, '/') + " " + times);
		return {
			dates,
			times,
			millisecond,
			dateObj
		};
	},
	// 去抖
	debounce(method, delay) {
		let timer = null;

		return function(...args) {
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(() => {
				method.call(this, ...args);
			}, delay);
		}
	},
	// 节流
	throttle(fn, delay = 1000) {
		let timer = null;
		let firstTime = true;

		return function(...args) {
			if (firstTime) {
				fn.call(this, ...args);
				return firstTime = false;
			}
			if (timer) {
				return;
			}
			timer = setTimeout(() => {
				clearTimeout(timer);
				timer = null;
				fn.call(this, ...args);
			}, delay);
		};
	},
	// deepMerge 深度合并数组或对象
	deepMerge(target, source) {
		if (!source) return target;
		let result = target;
		for (let key in source) {
			if (source.hasOwnProperty(key)) {
				if (source[key] && typeof source[key] === 'object') {
					result[key] = utils.deepMerge(target[key], source[key]);
				} else {
					result[key] = source[key];
				}
			}
		}
		return result;
	}
}

window.deepMerge = utils.deepMerge;
console.log(window.deepMerge, '>>>>>');

export default utils;
