<template>
	<div class="smart-city">
		<top-nav :img-list="banner" is-parent>
			<div class="banner-item-title" style="text-align: center;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<div class="card-box flex space-between">
					<pin-card style="height: 121px;" v-for="t in block1" :key="t.title" :title="t.title" :icon="t.icon" :sub="t.sub"></pin-card>
				</div>
				<block-title style="margin-top: 80px;" :data="static.title1"></block-title>
				<img class="b1-img" style="margin-top: 40px;" src="./assets/sc-b1-3.png" alt="">
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title style="margin-top: 80px;" :data="static.title2"></block-title>
				<card-list class="c-r" style="margin-top: 80px;" :list="block2"></card-list>
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<block-title style="margin-top: 80px;" :data="static.title3"></block-title>
			<display-list style="margin-top: 70px;" :list="block3"></display-list>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title4"></block-title>
				<card-list style="margin-top: 80px;" :list="block4"></card-list>
			</div>
		</div>
		<my-footer></my-footer>
	</div>
</template>

<script>
import PinCard from "../../components/card-list/pin-card.vue";
import CardList from "../../components/card-list/index.vue";
import DisplayList from "../../components/display-list/index.vue";
import { mapGetters } from "vuex";

export default {
	name: "smart-city",
	components: { DisplayList, CardList, PinCard },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '安全难以监测',
						sub: '事故影响大、安全范围难以评估',
						icon: require('./assets/sc-b1-0.png')
					},
					{
						title: '监控集成难',
						sub: '监管对象多、营运范围大',
						icon: require('./assets/sc-b1-1.png')
					},
					{
						title: '监管手段单一',
						sub: '执法范围大、执法效率差',
						icon: require('./assets/sc-b1-2.png')
					},
				],
				block2: [
					{
						title: '全域覆盖',
						description: '建设覆盖城市全域的物联感知平台，可支撑全域水陆空一体化的海量物联感知终端接入，实现全域无缝覆盖。',
						icon: require('./assets/s1.svg'),
						layout: 'y'
					},
					{
						title: '多云融合',
						description: '支持主流第三方的IoT平台融合的接入能力，实现平台与平台之间的互联互通.',
						icon: require('./assets/s2.svg'),
						layout: 'y'
					},
					{
						title: '标准能力构建',
						description: '依托物联感知平台，对设备终端、数据、应用等行业标准化建设，提供统一终端接入、统一数据处理、统一开放接口，构建物联感知建设导则，实现接入、调用、开发的敏捷性.\n',
						icon: require('./assets/s3.svg'),
						layout: 'y'
					},
					{
						title: '数据互通',
						description: '接入现有的智慧政务、智慧交通、智慧环境、智慧医疗、智慧校园、智慧社区等众多领域的物联平台，打破“数据孤岛”，推进信息资源数据交换和共享体系建设。\n',
						icon: require('./assets/s4.svg'),
						layout: 'y'
					},
					{
						title: '灵活部署',
						description: '面对laaS平台完全中立，支持公有云和私有云两种部署模式。\n',
						icon: require('./assets/s5.svg'),
						layout: 'y'
					},
					{
						title: '精细化管理',
						description: '借助物联网平台，整合部门、区县的城市管理服务相关信息化系统、数据资源，打造全市城市运行管理“一网共治”新格局，推动城市安全运行，优化管理流程，提升精细化管理水平。\n',
						icon: require('./assets/s6.svg'),
						layout: 'y'
					},
				],
				block3: [
					{
						title: '城市生命线',
						description: '城市生命线是保障城市正常运行的工程系统，涉及燃气、供水、排水、交通、通信、供电、桥梁等众多领域，其安全运行对于城市具有重大影响。随着先进智能传感器、无线通讯、大数据、人工智能等新兴技术的涌现和发展，带来了新的机遇。城市生命线需通过数智化的升级和赋能，提升城市应对重大灾害的能力，保障其服役安全性和抗灾害韧性。',
						icon: require('./assets/sc-b3-0.png'),
						btn: {
							label: '查看方案',
							onclick: () => this.$router.push('/smart-city/life-line')
						}
					},
					{
						title: '桥梁监测',
						description: '普适于桥梁、隧道等基础设施，可容纳公路沿线、城市区域的所有结构群风险源，做到信息化管理、数字化监测、智能化分析。',
						icon: require('./assets/sc-b3-1.png'),
						btn: {
							label: '查看方案',
							onclick: () => this.$router.push('/smart-city/bridge-monitor')
						}
					},
					{
						title: '地灾监测',
						description: '对隐患灾体的地表变形、深部位移、地表裂缝、地下水位、降雨量等进行实时监测，在智能网关进行边缘计算后，将数据上传至云平台，进行远程在线实时监控、分析和预警。',
						icon: require('./assets/sc-b3-2.png'),
						btn: {
							label: '查看方案',
							onclick: () => this.$router.push('/smart-city/disaster-monitor')
						}
					},
				],
				block4: [
					{
						title: '零风险',
						description: '通过传感设备智能化的感知结构物信息，无需人员在场',
						icon: require('./assets/sc-b4-0.png'),
						layout: 'y'
					},
					{
						title: '降低运营维护成本',
						description: '一次投入，可长期监测数据的变化，提供设备告警信息',
						icon: require('./assets/sc-b4-1.png'),
						layout: 'y'
					},
					{
						title: '快速反应提高效率',
						description: '利用物联网技术24 小时实时监测设备数据变化，第一时间将预警信息自动发送至相关人员\n',
						icon: require('./assets/sc-b4-2.png'),
						layout: 'y'
					},
				]
			}
		}
	},
	computed:{
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-city']['index']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
		block4() {
			return window.deepMerge(this.defines.block4, this.static.block4)
		},
		banner () {
			return require('./assets/sc-banner.png')
		},
	}
}
</script>

<style scoped lang="scss">
.smart-city {
	.use-bg {
		background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b1-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 674px;
	}
	.c-r {
		:deep(img) {
			width: 150px;
			height: 150px;
			object-fit: contain;
		}
	}
}
</style>
