import { render, staticRenderFns } from "./product-list.vue?vue&type=template&id=33d21d20&scoped=true"
import script from "./product-list.vue?vue&type=script&lang=js"
export * from "./product-list.vue?vue&type=script&lang=js"
import style0 from "./product-list.vue?vue&type=style&index=0&id=33d21d20&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_zh5i3lkul6ts2ya5x5g6w3bfg4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d21d20",
  null
  
)

export default component.exports