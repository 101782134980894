<template>
	<div class="footer-resolution">
		<div class="block-content layout">
			<div class="left">
				<div class="f-title">{{ renderInfo.title }}</div>
				<div class="f-en">{{ renderInfo.en.toUpperCase() }}</div>
				<div class="f-sub">{{ renderInfo.sub }}</div>
			</div>
			<div class="right">
				<div class="ask-btn" @click="sendInfo">立即申请</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "footer-resolution",
	props: {
		title: {
			type: String,
			default: ''
		},
		en: {
			type: String,
			default: ''
		},
		sub: {
			type: String,
			default: ''
		},
		data: {
			type: [Object, undefined],
		}
	},
	computed: {
		renderInfo() {
			return this.data || {
				title: this.title,
				en: this.en,
				sub: this.sub
			}
		}
	},
	methods: {
		sendInfo() {
			this.$router.push({
				name: "get-plan",
				params: {},
			});
		},
	},
}
</script>

<style scoped lang="scss">
.footer-resolution {
	height: 304px;
	background: url("./assets/footer-resolution.png") center no-repeat;
	background-size: cover;

	.layout {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 20px;
	}
	.f-title {
		font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
		font-weight: 400;
		font-size: 36px;
		color: #333333;
		margin-top: 60px;
	}
	.f-en {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 500;
		font-size: 24px;
		color: #999999;
		margin-top: 10px;
	}
	.f-sub {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 24px;
		color: #999999;
		margin-top: 40px;
	}
	.ask-btn {
		width: 200px;
		height: 60px;
		background: linear-gradient( 180deg, #4E99FD 0%, #5482FA 100%);
		box-shadow: 3px 3px 8px 1px #E8E8E8;
		border-radius: 2px 2px 2px 2px;
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 24px;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 100px;
		cursor: pointer;
	}
}
</style>
