<template>
  <div class="container">
    <top-nav :imgList="banner">
      <div class="banner-item-title">
        {{ globalData.smartEnforceResolution.title }}
      </div>
      <div class="banner-item-brief">
        {{ globalData.smartEnforceResolution.brief }}
      </div>
    </top-nav>
    <div class="structure">
      <div
        class="card-box flex space-between content-limit"
        style="margin-bottom: 80px"
      >
        <pin-card
          v-for="t in block1"
          :key="t.title"
          :title="t.title"
          :icon="t.icon"
          :sub="t.sub"
        ></pin-card>
      </div>
      <block-title
        :title="globalData.smartEnforceResolution.structure.top.title"
        :en="globalData.smartEnforceResolution.structure.top.en"
        :sub="globalData.smartEnforceResolution.structure.top.sub"
      ></block-title>
      <img
        class="img content-limit"
        src="@/asset/smart-traffic/ic_traffic_78.png"
        alt=""
      />
    </div>
    <div class="feature">
      <block-title
        :title="globalData.smartEnforceResolution.feature.top.title"
        :en="globalData.smartEnforceResolution.feature.top.en"
        :sub="globalData.smartEnforceResolution.feature.top.sub"
      ></block-title>
      <div class="content-limit">
        <card-list :list="block4" style="margin-top: 80px"></card-list>
      </div>
    </div>
    <div class="application content-limit">
      <block-title
        :title="globalData.smartEnforceResolution.application.top.title"
        :en="globalData.smartEnforceResolution.application.top.en"
        :sub="globalData.smartEnforceResolution.application.top.sub"
      ></block-title>
      <div class="brief">
        {{ globalData.smartEnforceResolution.application.brief }}
      </div>
      <div class="list">
        <img
          class="item"
          src="@/asset/smart-traffic/ic_traffic_85.png"
          alt=""
        />
        <img
          class="item"
          src="@/asset/smart-traffic/ic_traffic_84.png"
          alt=""
        />
      </div>
    </div>
    <div class="footer-resolution">
      <div class="block-content layout">
        <div class="left">
          <div class="f-title">
            {{ globalData.smartEnforceResolution.footer.title }}
          </div>
          <div class="f-en">
            {{ globalData.smartEnforceResolution.footer.en.toUpperCase() }}
          </div>
          <div class="f-sub">
            {{ globalData.smartEnforceResolution.footer.sub }}
          </div>
        </div>
        <div class="right">
          <div class="ask-btn">立即申请</div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
import PinCard from "../../components/card-list/pin-card.vue";
import CardList from "../../components/card-list/index.vue";

export default {
  computed: {
    ...mapGetters(["globalData"]),
    block1() {
      return window.deepMerge(
        [
          {
            title: "产品优势",
            sub: "物联网+互联网+人工智能新技术",
            icon: require("@/asset/smart-traffic/ic_traffic_74.png"),
          },
          {
            title: "团队优势",
            sub: "归纳反馈，研判升级",
            icon: require("@/asset/smart-traffic/ic_traffic_77.png"),
          },
          {
            title: "技术优势",
            sub: "建造优质项目，我们从容有余",
            icon: require("@/asset/smart-traffic/ic_traffic_75.png"),
          },
          {
            title: "服务优势",
            sub: "产品落地，我们有规划",
            icon: require("@/asset/smart-traffic/ic_traffic_76.png"),
          },
        ],
        this.globalData.smartEnforceResolution.structure.block1
      );
    },
    block4() {
      return window.deepMerge(
        [
          {
            title: "车牌识别及车头抓拍",
            description:
              "车头方向安装一台高清车牌一体机，对车头进行抓拍、并实时识别车牌号码，车号和车头照片自动上传到工控机",
            icon: require("@/asset/smart-traffic/ic_traffic_79.png"),
          },
          {
            title: "车尾抓拍",
            description:
              "车尾方向安装一台高清车牌一体机，对车尾进行抓拍，车尾照片自动上传到工控机",
            icon: require("@/asset/smart-traffic/ic_traffic_80.png"),
          },
          {
            title: "车身抓拍",
            description:
              "车辆行进侧面方向安装一台高清车牌一体机，对货车的侧面进行抓拍，车身照片自动上传工控机",
            icon: require("@/asset/smart-traffic/ic_traffic_81.png"),
          },
          {
            title: "车牌识别",
            description:
              "车牌识别是遴选车辆和执法取证的关键功能一，采用车牌一体机对过往货车进行抓拍和动态车牌识别。为了检测系统证据的完整性，分别设置车头、车尾、车身三个不同角度的抓拍摄像机。",
            icon: require("@/asset/smart-traffic/ic_traffic_82.png"),
          },
          {
            title: "抓拍配套设备",
            description:
              "为了保证全天候的抓拍照片效果，每个车道安装一个频闪补光灯和爆闪灯，确保白天和晚上抓图片效果一致性。可变情报板用于不停车预检统，主要用于诱导、超限超载车辆信息公告发布。",
            icon: require("@/asset/smart-traffic/ic_traffic_83.png"),
          },
        ],
        this.globalData.smartEnforceResolution.feature.block4
      );
    },
  },
  components: {
    PinCard,
    CardList,
  },
  data() {
    return {
      banner: require("@/asset/smart-traffic/ic_traffic_01.png"),
    };
  },
};
</script>
  <style lang="scss" scoped>
.structure {
  padding: 100px 0 80px;
  .img {
    display: block;
    margin-top: 40px;
  }
}
.feature {
  background: url(@/asset/smart-traffic/ic_traffic_65.png) no-repeat
    center/cover;
  padding: 80px 0 40px;
}
.footer-resolution {
  height: 304px;
  background: url(@/asset/smart-traffic/ic_traffic_86.png) no-repeat
    center/cover;

  .layout {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
  }
  .f-title {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 36px;
    color: #333333;
    margin-top: 60px;
  }
  .f-en {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 24px;
    color: #999999;
    margin-top: 10px;
  }
  .f-sub {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    margin-top: 40px;
  }
  .ask-btn {
    width: 200px;
    height: 60px;
    background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
    box-shadow: 3px 3px 8px 1px #e8e8e8;
    border-radius: 2px 2px 2px 2px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
}
.application {
  padding: 80px 0;
  .brief {
    font-size: 18px;
    color: #666666;
    line-height: 2;
    margin-top: 68px;
  }
  .list {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 70px;
    .item {
      flex-grow: 1;
    }
  }
}
</style>
  