<template>
	<div class="introduce-list">
		<div class="il-item" :style="{height: t.height + 'px'}" :class="{revert: i%2}" v-for="(t, i) in list" :key="i">
			<div class="relative w-full">
				<div class="full-center">
					<div class="il-info">
						<div class="il-hr"></div>
						<div class="il-en">{{ String(t.en).toUpperCase() }}</div>
						<div class="il-title">{{ t.title }}</div>
						<div class="il-description">{{ t.description }}</div>
						<div class="il-index">{{ String(i + 1).padStart(2, '0') }}.</div>
					</div>
				</div>
			</div>
			<div class="il-image-box">
				<img :src="t.icon" alt="">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "introduce-list",
	props: {
		list: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style scoped lang="scss">
.introduce-list {
	display: flex;
	flex-direction: column;
	gap: 80px;

	.il-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.full-center {
			left: 0!important;
			transform: translateY(-50%)!important;
		}

		&.revert {
			justify-content: flex-end;
			.il-info {
				grid-template-areas:
				'b a'
				'c f'
				'd f'
				'g e';
			}
			.full-center {
				left: unset!important;
				right: 0;
				transform: translateY(-50%)!important;
				text-align: right;
				display: flex;
				justify-content: flex-end;
			}
			.il-image-box {
				right: unset;
				left: 0;
				text-align: right;
			}
			.il-description {
				justify-self: flex-end;
			}
		}

		.il-info {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-rows: auto auto auto auto;
			align-items: center;
			grid-template-areas:
				'a b'
				'f c'
				'f d'
				'e g';
			column-gap: 20px;
		}
		.il-hr {
			grid-area: a;
			width: 80px;
			height: 4px;
			background: #4E99FD;
			align-self: center;
		}
		.il-en {
			grid-area: b;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: bold;
			font-size: 24px;
			color: #DDDDDD;
			white-space: nowrap;
		}
		.il-title {
			grid-area: c;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: bold;
			font-size: 24px;
			color: #4E99FD;
			margin-top: 14px;
		}
		.il-description {
			grid-area: d;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 18px;
			color: #666666;
			line-height: 36px;
			margin-top: 80px;
			width: 496px;
		}
		.il-index {
			grid-area: e;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: bold;
			font-size: 24px;
			color: #666666;
			margin-top: 60px;
		}

		.il-image-box {
			position: absolute;
			width: 700px;
			right: 0;
			background: #F8F8F8;
		}
	}
}
</style>
