<template>
	<div class="bridge-monitor">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: left;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list style="margin-top: 80px;" :list="block1"></card-list>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<img class="b2-img" style="margin-top: 40px;" src="./assets/bm-b2.png" alt="">
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<card-list style="margin-top: 80px;" :list="block2"></card-list>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title4"></block-title>
				<div class="bridge-list">
					<div class="btn" :class="{selected: current === 0}" @click="current=0">斜拉桥</div>
					<div class="btn" :class="{selected: current === 1}" @click="current=1">拱桥</div>
					<div class="btn" :class="{selected: current === 2}" @click="current=2">梁式桥</div>
					<div class="btn" :class="{selected: current === 3}" @click="current=3">悬索桥</div>
				</div>
				<img class="b-image" :src="imgs[current]" alt="">
			</div>
		</div>
		<div class="block-area pt-80px">
			<div class="block-content">
				<block-title :data="static.title5"></block-title>
				<div class="zap-list" style="margin-top: 80px;">
					<div class="zap-item">
						<img src="./assets/bm-b5-0.png" alt="">
						<div class="zap-title" v-html="static.p1[0]"></div>
					</div>
					<div class="zap-item">
						<img style="margin-top: 21px;" src="./assets/bm-b5-arrow.png" alt="">
					</div>
					<div class="zap-item">
						<img src="./assets/bm-b5-1.png" alt="">
						<div class="zap-title" v-html="static.p1[1]"></div>
					</div>
					<div class="zap-item">
						<img style="margin-top: 21px;" src="./assets/bm-b5-arrow.png" alt="">
					</div>
					<div class="zap-item">
						<img src="./assets/bm-b5-2.png" alt="">
						<div class="zap-title" v-html="static.p1[2]"></div>
					</div>
					<div class="zap-item">
						<img style="margin-top: 21px;" src="./assets/bm-b5-arrow.png" alt="">
					</div>
					<div class="zap-item">
						<img src="./assets/bm-b5-3.png" alt="">
						<div class="zap-title" v-html="static.p1[3]"></div>
					</div>
				</div>
				<div class="zig-card" style="margin-top: 40px;">
					<img src="./assets/bm-b6.png" alt="">
					<div class="word-context">
						<div v-for="t in static.p2" :key="t">{{ t }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title6"></block-title>
				<div class="img-gallery" style="margin-top: 80px;">
					<img style="grid-area: a" src="./assets/bm-b7-0.webp" alt="">
					<img src="./assets/bm-b7-1.webp" alt="">
					<img src="./assets/bm-b7-2.webp" alt="">
					<img src="./assets/bm-b7-3.webp" alt="">
					<img src="./assets/bm-b7-4.webp" alt="">
				</div>
			</div>
		</div>
		<footer-resolution :data="static.title7"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CardList from "../../components/card-list/index.vue";

export default {
	name: "bridge-monitor",
	components: { CardList },
	data: function () {
		return {
			current: 0,
			imgs: [
				require('./assets/b1.webp'),
				require('./assets/b2.webp'),
				require('./assets/b3.webp'),
				require('./assets/b4.webp'),
			],
			defines: {
				block1: [
					{
						title: '全感知',
						description: '7x24小时自动化采集全面感知核心要素数据连续、精准',
						icon: require('./assets/bm-b1-0.png'),
						style: {
							height: window.flexible.px2rem(330) + 'rem'
						},
					},
					{
						title: '云计算',
						description: '实时数据的汇聚分析时空一体的计算框架强化智能的计算能力',
						icon: require('./assets/bm-b1-1.png'),
						style: {
							height: window.flexible.px2rem(330) + 'rem'
						},
					},
					{
						title: '强分析',
						description: '利用人工智能，大数据分析算法从海量数据中寻找价值信息深度挖掘多源异构监测数据实现数据赋能，指导科学养护',
						icon: require('./assets/bm-b1-2.png'),
						style: {
							height: window.flexible.px2rem(330) + 'rem'
						},
					},
					{
						title: '速决策',
						description: '基于感知数据、专业化分析综合评估桥梁健康状态结合养护规范精准、科学养护',
						icon: require('./assets/bm-b1-3.png'),
						contentWidth: '65%',
						style: {
							height: window.flexible.px2rem(205) + 'rem'
						},
					},
					{
						title: '优服务',
						description: '规划设计、项目建设、运营维护一体化服务专家级团队专业化分析、评估',
						icon: require('./assets/bm-b1-4.png'),
						style: {
							height: window.flexible.px2rem(205) + 'rem'
						},
					},
				],
				block2: [
					{
						title: '软件',
						description: '自主研发桥梁健康监测新一代智能评估平台，集成丰富的数据算法和多种预警方式为专业桥梁工程师提供可靠快速的分析入口。',
						icon: require('./assets/bm-b3-0.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(243) + 'rem'
						},
					},
					{
						title: '硬件',
						description: '自主研发机器视觉智能测量系统，实现动、静态高精度挠度测量。采集边缘计算终端，低功耗高集成，可兼容桥梁大部分',
						icon: require('./assets/bm-b3-1.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(243) + 'rem'
						},
					},
					{
						title: '案例',
						description: '30+座桥梁就监测案例城市市政桥梁群体健康综合管理、拱桥结构监测、高速大桥健康监测等',
						icon: require('./assets/bm-b3-2.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(243) + 'rem'
						},
					},
					{
						title: '专家团队',
						description: '高校专家组团队，专业化分析、科学化决策。一站式规划设计、项目建设运维维护服务',
						icon: require('./assets/bm-b3-3.png'),
						contentWidth: '60%',
						style: {
							height: window.flexible.px2rem(243) + 'rem'
						},
					},
				]
			}
		}
	},
	computed: {
		...mapGetters(['globalData']),
		static() {
			return this.globalData['smart-city']['bridge-monitor']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		banner () {
			return require('./assets/sc-ll-banner.png')
		},
	}
}
</script>

<style scoped lang="scss">
.bridge-monitor {
	.use-bg {
		background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}

	.b2-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 581px;
	}

	.b4-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 596px;
	}

	.zap-list {
		display: flex;
		justify-content: space-between;

		.zap-item {
			display: flex;
			flex-direction: column;
			align-items: center;

			.zap-title {
				text-align: center;
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 500;
				font-size: 24px;
				color: #333333;
				line-height: 48px;
			}
		}
	}

	.zig-card {
		display: flex;
		align-items: center;

		.word-context {
			width: 576px;
			height: 337px;
			box-sizing: border-box;
			background: #FFFFFF;
			border-radius: 1px 1px 1px 1px;
			padding: 32px 60px;
			display: flex;
			gap: 5px;
			justify-content: center;
			flex-direction: column;
			transform: translateX(-30px);

			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 14px;
			color: #666666;
			line-height: 28px;

			filter: drop-shadow(-5px 10px 0 rgba(0, 0, 0, 0.25));

			div {
				position: relative;

				&:before {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 12px;
					height: 12px;
					background: rgba(78, 153, 253, 1);
					border-radius: 50%;
					transform: translate(-20px, 8px);
				}
			}
		}
	}

	.img-gallery {
		display: grid;
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(3, 1fr);
		gap: 40px;
		grid-template-areas:
			'a b c'
			'a d e';
	}

	.bridge-list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		height: 78px;
		margin-top: 24px;
		.btn {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 22px;
			color: #666666;
			background-color: white;
			&.selected {
				background: #4E99FD;
				color: white;
			}
		}
	}
	.b-image {
		width: 1280px;
		height: 530px;
		object-fit: cover;
	}
}
</style>
