<template>
	<div class="reservoir-project">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: left;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list :list="block1" style="margin-top: 80px;"></card-list>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<img class="b2-img" style="margin-top: 40px;" src="./assets/rp-b2.png" alt="">
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<introduce-list style="margin-top: 80px;" :list="block2"></introduce-list>
			</div>
		</div>
		<footer-resolution :data="static.title4"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import CardList from "../../components/card-list/index.vue";
import IntroduceList from "../../components/introduce-list/index.vue";
import { mapGetters } from "vuex";

export default {
	name: "reservoir-project",
	components: { IntroduceList, CardList },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '水利工程监测的必要性',
						description: '建立在无粘性土上的水库大坝，四种渗透破坏形式均有可能发生汛期更容易造成决口，后果十分严重，且不易察觉，给防范工作带来很大的难度',
						icon: require('./assets/rp-b1-0.png'),
					},
					{
						title: '常见问题',
						description: '除了蓄水引发的水库地震外，渗透破坏是平原区水库大坝发生破坏的主要形式，从渗透破坏发生机理的角度将渗透破坏形式分为流土、管涌、接触流土、接触冲刷四种类型。',
						icon: require('./assets/rp-b1-1.png'),
					},
					{
						title: '遵循的规范',
						description: '《土石坝安全监测技术规范》(SL551-2012)\n《混凝土大坝安全监测技术规范》(SDJ336-89)',
						icon: require('./assets/rp-b1-2.png'),
					},
				],
				block2: [
					{
						title: '北斗监测，安全可控',
						en: 'Beidou monitoring, safe and controllable',
						description: '使用全国产化的北斗卫星系统和相关技术装备，抗干扰性好、自主可控，降低安全风险，且系统操作便捷、可维护性强。',
						icon: require('./assets/rp-b3-0.png'),
						height: 505,
					},
					{
						title: '全链路自动化，降低人力成本',
						en: 'Full chain automation reduces labor costs',
						description: '数据采集、处理、输出、展示全链路自动化监测，减轻人工测量难度，降低人为误差，确保监测成果的高精准度和高时效性。',
						icon: require('./assets/rp-b3-1.png'),
						height: 505,
					},
					{
						title: '监测精度高，服务性能可靠',
						en: 'reliable service performance',
						description: '创新的区域组网平差和卫星误差消除专利技术，经过权威单位验证，保障高山、峡谷环境下监测成果的可靠性与高精度。',
						icon: require('./assets/rp-b3-2.png'),
						height: 505,
					},
				]
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-water']['reservoir-project']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		banner () {
			return require('./assets/smart-water-conservancy-banner.png')
		},
	},
}
</script>

<style scoped lang="scss">
.reservoir-project {
	.use-bg {
		background: url("./assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b2-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 693px;
	}
}
</style>
