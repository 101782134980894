<template>
	<div class="introduce-carousel pt-80px pb-80px">
		<img class="i-bg" :src="list[index].bg" alt="">
		<div class="title-content">
			<div class="title">{{ title }}</div>
			<div class="sub-title-en">{{ en.toUpperCase() }}</div>
			<div class="sub-title">{{ sub }}</div>
		</div>
		<div class="block-content mt-80px">
			<div class="b-left">
				<div class="b-nav" :style="{ gridTemplateColumns: `repeat(${list.length}, 1fr)`}">
					<div class="bn-item" v-for="(t, i) in list" :class="{checked: index === i}" @click="index = i" :key="i">
						<img class="normal" :src="t.icon" alt="">
						<img class="hover" :src="t.iconHover" alt="">
						<span>{{ t.title }}</span>
					</div>
				</div>
				<div class="b-border">
					<div class="b-border-inner" :style="borderStyle"></div>
				</div>
				<div class="b-sub">{{ list[index].sub }}</div>
				<div class="b-description">{{ list[index].description }}</div>
			</div>
			<div class="b-right">
				<div v-for="(t, i) in list" :key="i">
					<Transition name="opacity">
						<img :src="t.iconImg" v-if="index === i" alt="">
					</Transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "introduce-carousel",
	data: function () {
		return {
			index: 0,
		}
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		en: {
			type: String,
			default: ""
		},
		sub: {
			type: String,
			default: ""
		},
		list: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		borderStyle () {
			const each = (1 / this.list.length * 100).toFixed(2);
			return {
				width: each + '%',
				left: each * this.index + '%'
			}
		}
	}
}
</script>

<style scoped lang="scss">
.introduce-carousel {
	box-sizing: border-box;
	position: relative;

	.i-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		object-fit: cover;
		z-index: -1;
	}

	.title-content {
		text-align: center;
	}

	.title {
		font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
		font-weight: 400;
		font-size: 36px;
		color: #FFFFFF;
	}

	.sub-title-en {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: bold;
		font-size: 36px;
		color: rgba(#FFFFFF, 0.4);
		margin-top: 13px;
	}

	.sub-title {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		color: rgba(#FFFFFF, 0.6);
		margin-top: 40px;
	}

	.block-content {
		display: grid;
		grid-template-columns: 1fr 632px;
		gap: 100px;
		align-items: flex-start;
	}

	.b-left {
		display: flex;
		flex-direction: column;
	}

	.b-nav {
		display: grid;
	}

	.bn-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		span {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 20px;
			color: #FFFFFF;
			margin-top: 8px;
		}

		.hover {
			display: none;
		}

		&:hover, &.checked {
			span {
				font-weight: bold;
				font-size: 20px;
				color: #FFFFFF;
			}

			.hover {
				display: block;
			}

			.normal {
				display: none;
			}
		}
	}

	.b-border {
		position: relative;
		width: 100%;
		height: 2px;
		background: rgba(#FFFFFF, 0.6);
		margin-top: 40px;

		.b-border-inner {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			height: 4px;
			background: rgba(78, 153, 253, 1);
			transition: left .2s;
		}
	}

	.b-sub {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 500;
		font-size: 36px;
		color: #FFFFFF;
		margin-top: 40px;
	}

	.b-description {
		margin-top: 24px;
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 40px;
	}

	.b-right div {
		position: relative;
		img {
			position: absolute;
		}
	}
}
.opacity-enter-active {
	animation: fadeIn 0.3s;
}
.opacity-leave-active {
	animation: fadeIn 0.3s reverse;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
